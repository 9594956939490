<p-confirmDialog class="confirmDialog" #cd [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
      <h3>Cadastro realizado</h3>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Ok"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>
  
<div class="forgot-passwd-wrapper">
<svg-icon class="svg-icon" name="forgot-passwd" ></svg-icon>
  <div class="text">Insira seu e-mail e lhe enviaremos um link para resetar sua senha.</div>
  <div class="email-input">
    <input class="input" [(ngModel)]="email" placeholder="user@email.com" type="text" />
  </div>
  <button (click)="sendResetPasswdEmail()" class="primary-button">Enviar</button>

  <div class="logo">
    <img src="/assets/layout/images/logo-azul-scuro.png" alt="">
  </div>
</div>
