import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripeUrl = environment.stripeApiUrl;
  private apiUrl = environment.apiUrl;
  // private apiUrl = 'http://localhost:3000/';

  constructor(private http: HttpClient) {}
  migratePlan(planId: string, customerId: string, subscriptionType: string) {
    const body: any = {
      productId: planId,
      customerId: customerId,
      subscriptionType: subscriptionType
    };

    return this.http.post(`${this.apiUrl}migrate-plan`, body);
  }

  getCustomer(email: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + environment.stripeSecretKey,
    });

    const params = new HttpParams().set('email', email);

    return this.http.get(`${this.stripeUrl}customers`, { headers, params });
  }

  // attachCardToCustomer(cardId: string, customerId: string): Observable<any> {
  //   const body = new URLSearchParams();
  //   body.set('customer', customerId);
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: 'Bearer ' + environment.stripeSecretKey,
  //   });

  //   return this.http.post(
  //     `${this.stripeUrl}payment_methods/${cardId}/attach`,
  //     body.toString(),
  //     { headers }
  //   );
  // }

  // getCardsAttached(customerId: string): Observable<any> {
  //   const headers = new HttpHeaders({
  //     Authorization: 'Bearer ' + environment.stripeSecretKey,
  //   });
  //   return this.http.get(
  //     `${this.stripeUrl}customers/${customerId}/payment_methods`,
  //     { headers }
  //   );
  // }
}
