import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class PasswordResetComponent implements OnInit {
  token: any;
  tokenInfo: any;
  email: any;
  user: any;
  userId: any;
  userName: any;
  passwd: any;
  isValidationModalOpen: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('token')?.toString();
      try {
        this.tokenInfo = jwtDecode(this.token);
        this.tokenInfo = this.tokenInfo;
        this.email = this.tokenInfo.email;
        this.userId = this.tokenInfo.userId;
      } catch {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail:
            'Erro ao decodificar o token, seu link pode estar vencido. Tente novamente.',
        });
        this.router.navigateByUrl('/');
      }

      if (this.tokenInfo) {
      }
    });
  }

  getPassConfirmationResult(event: boolean) {
    if (event) {
      this.authService.resetPassword(this.passwd, this.token).subscribe(
        (result: any) => {
          this.confirmationService.confirm({
            message: 'Sua senha foi alterada com sucesso!',
            header: 'Senha alterada',
            icon: 'pi pi-check',
            accept: () => {
              this.router.navigateByUrl('/');
            },
          });
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail:
              'Erro ao decodificar o token, seu link pode estar vencido. Tente novamente.',
          });
          this.router.navigateByUrl('/');
        }
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'As senhas não são iguais.',
      });
    }
  }
}
