export class User {
  //TODO: ver quais são realmente obrigatórios
  id?: any = null;
  name: string = '';
  surname?: string = '';
  cpf: any = '';
  nickname?: string = '';
  gender: any = null;
  birthday: any;
  email: string = '';
  password: string = '';
  userType?: UserType = UserType.Invited;
  profilePicture?: any;
  registrationDate: Date | undefined;
  active: boolean = true;
  description: string = '';
  profileBanner?: any;
  phoneNumber: any;
  profiles?: any[] = ['guest'];
  profileBackgroundFormat?: any;
  profileBackground?: any;
  profileImgFormat?: any;
  profileImg?: any;
  specialty?:any;
  address: Address = {
    cep: '',
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    complement: '',
    number: '',
  };
  tel?: any;

  //host fields
  plan?: any;
  isPaymentUpToDate?: boolean = true;
  dueDate?: Date = new Date();
}
export enum Gender {
  Fem = 'female',
  Male = 'male',
  Oth = 'other',
}
export enum UserType {
  Host = 'host',
  Invited = 'guest',
}

export class Address {
  cep: any;
  country: any;
  state: any;
  city: any;
  neighborhood: any;
  street: any;
  complement: any;
  number: any;
}
