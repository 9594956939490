<div class="event-card-wrapper">
  <div
    class="banner"
    pTooltip="Ver mais"
    tooltipPosition="top"
    (click)="openEventDetails()"
  >
    <img id="banner" [src]="event.url_thumbnail" alt="" />
  </div>
  <div class="infos">
    <h5 class="date-info">
      {{ event.initial_date | date : "dd/MM/yyyy" }}, às
      {{ event.initial_date | date : "HH:mm" }}
    </h5>
    <h3 class="title">{{ event.title }}</h3>
    <div class="card-footer" *ngIf="showfooter">
      <div class="host w-100">
        <div class="host-pic">
          <img id="host-pic" [src]="host.profileImg" alt="" />
        </div>
        <div class="host-infos">
          <h6>Anfitrião</h6>
          <h4 class="host-name">{{ host.name }} {{ host.surname }}</h4>
        </div>
      </div>
      <div class="btn">
        <button
        [disabled]="isConfirmed"
        *ngIf="isInviteVersion"
        (click)="confirmPresence()"
        class="primary-button"
      >
        Confirmar
      </button>
      <button
        *ngIf="!isInviteVersion && currentTabId !== 'Meus Eventos'"
        (click)="subscribeToEvent.emit(event)"
        class="primary-button"
      >
        Inscrever-se
      </button>
      </div>
      <p-toast position="top-left"></p-toast>
    </div>
  </div>
  <div class="app-events"></div>
</div>
