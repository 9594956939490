import { LandingPagePrototypeComponent } from './modules/public/landing-page-prototype/landing-page-prototype.component';
import { UserHomeComponent } from './components/user-home/user-home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailConfirmationComponent } from './modules/public/email-confirmation/email-confirmation.component';
import { MediaAdminComponent } from "./components/media-admin/media-admin.component";
import { DashboardComponent } from './modules/guest/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { LoginComponent } from './modules/public/login/login.component';
import { UseTermsComponent } from './modules/public/use-terms/use-terms.component';
import { ForgotPasswdComponent } from './modules/public/forgot-passwd/forgot-passwd.component';
import { EventDetailsComponent } from './modules/shared/events/event-details/event-details.component';
import { PasswordResetComponent } from './modules/public/password-reset/password-reset.component';
import { CheckoutComponent } from './modules/guest/checkout/checkout.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'terms',
    component: UseTermsComponent,
  },
  {
    path: 'email-confirmation/:token',
    component: EmailConfirmationComponent,
    canActivate: [GuestGuard],
  },{
    path: 'reset-password/:token',
    component: PasswordResetComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'event/:eventId',
    component: EventDetailsComponent,
  },
  {
    path: 'sign-up',
    component: LandingPagePrototypeComponent,
  },
  {
    path: 'forgot-passwd',
    component: ForgotPasswdComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'media-admin/:folder',
    component: MediaAdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'landing',
    component: LandingPagePrototypeComponent,
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/guest/guest.module').then((m)=> m.GuestModule)
  },
  {
    path: 'hosts',
    loadChildren: () =>
      import('./modules/hosts/hosts.module').then((m)=> m.HostsModule)
  },
  {
    path: 'app/checkout',
    component: CheckoutComponent, 
  },
  {
    path: '**',
    redirectTo: 'app',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
