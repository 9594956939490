import { Event } from './../../models/event';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss'],
})
export class UserHomeComponent implements OnInit {
  constructor() {}

  events: Event[] = [
    {
      title: 'Vencendo a acrofobia',
      id: '',
      url_event:'',
      url_thumbnail: '',
      created_at: new Date(),
      timeTillDate: new Date(),
    },
    {
      title: 'Turismo',
      id: '',
      url_event:'',
      url_thumbnail: '',
      created_at: new Date(),
      timeTillDate: new Date(),
    },
    {
      title: 'Show',
      id: '',
      url_event:'',
      url_thumbnail: '',
      created_at: new Date(),
      timeTillDate: new Date(),
    },
    {
      title: 'Meditação guiada',
      id: '',
      url_event:'',
      url_thumbnail: '',
      created_at: new Date(),
      timeTillDate: new Date(),
    },
    {
      title: 'vencendo a demofobia',
      id: '',
      url_event:'',
      url_thumbnail: '',
      created_at: new Date(),
      timeTillDate: new Date(),
    },
  ];
  ngOnInit(): void {}
}
