<div class="card-payment form">
    <h2 class="mb-4">Cartão de Crédito</h2>
    <div class="row">
        <div class="card-number form-item col-md-12">
            <label for="ccn">Número do Cartão:</label>
            <p-inputMask  class="input" type="tel"
                [maxlength]="19" placeholder="xxxx xxxx xxxx xxxx"
                mask="9999 9999 9999 9999"
                slotChar=""
                [(ngModel)]="creditCard.number" ></p-inputMask>
        </div>
    </div>
    <div class="row">
        <div class="form-item col-md-5">
            <label for="cpf">CPF do Titular:</label>
            <input id="cpf" class="input" placeholder="Digite o CPF do titular" type="text" [(ngModel)]="creditCard.document"
                pInputText />
        </div>

        <div class="form-item col-md-7">
            <label for="name">Nome do Titular:</label>
            <input id="name" class="input" placeholder="Digite o nome do titular" type="text" [(ngModel)]="creditCard.holder_name"
                pInputText />
        </div>
    </div>
    <div class="row">
        <div class="card-number form-item col-md-7">
            <label for="conf">Dígitos de Confirmação:</label>
            <input id="conf" class="input" inputmode="numeric" autocomplete="conf-number" maxlength="3"
                placeholder="xxx" [(ngModel)]="creditCard.cvv" />
        </div>
        <div class="form-item col-md-2">
            <label class="block">Mês de Vencimento:</label>
            <input type="number" class="input" [(ngModel)]="creditCard.exp_month" />
        </div>
        <div class="form-item col-md-2">
            <label class="block">Ano de Vencimento:</label>
            <input type="number" class="input" [(ngModel)]="creditCard.exp_year" />
        </div>
    </div>

    <!-- Address -->
    <div class="row">
        <div class="form-item col-md-5">
            <label for="cpf">Endereço</label>
            <input id="cpf" class="input" placeholder="Rua, Numero, Bairro, Cidade" type="text"
                [(ngModel)]="creditCard.billing_address.line_1" pInputText />
        </div>

        <div class="form-item col-md-7">
            <label for="name">Complemento</label>
            <input id="name" class="input" placeholder="Próx a Praça" type="text"
                [(ngModel)]="creditCard.billing_address.line_2" pInputText />
        </div>
    </div>

    <div class="row">
        <div class="form-item col-md-3">
            <label for="cpf">CEP</label>
            <input id="cpf" class="input" placeholder="25953135" type="text"
                [(ngModel)]="creditCard.billing_address.zip_code" pInputText />
        </div>

        <div class="form-item col-md-3">
            <label for="name">Cidade</label>
            <input id="name" class="input" placeholder="Próx a Praça" type="text"
                [(ngModel)]="creditCard.billing_address.city" pInputText />
        </div>

        <div class="form-item col-md-2">
            <label for="name">Estado</label>
            <input id="name" class="input" placeholder="RJ, ES, BH" type="text"
                [(ngModel)]="creditCard.billing_address.state" pInputText />
        </div>

        <!--
            <div class="form-item col-md-4">
                <label for="name">País</label>
                <input id="name" class="input" placeholder="Brasil, Estados Unidos, Europa" type="text"
                    [(ngModel)]="creditCard.billing_address.country" pInputText />
            </div>
        -->
    </div>

    <div class="row">
        <div class="d-flex justify-content-end w-100">
            <button class="primary-button btn-danger col-md-2 mt-4" (click)="modalClosed.next()">
                Cancelar
            </button>
            <button class="primary-button col-md-3 mt-4 ml-2" (click)="addCard()">
                Adicionar cartão
            </button>

        </div>
        <span class="message-error" *ngIf="hasError">Ocorreu um erro ao adicionar o cartão, verifique os dados inseridos.</span>
    </div>

</div>
