import { SetProductDto } from "./DTOs/product-dto";

export class Event {

  id?: string = '';
  title?: string = '';
  timeTillDate?: Date = new Date();
  thumbnail?: any;
  description?: string | undefined = '';
  thumbnail_url?: string = '';
  created_at?: Date = new Date();
  updated_at?: Date;
  user_id?: string = '';
  initial_date?: Date;
  final_date?: Date;
  initial_selected_hour?: Date;
  duration?: any = 30;
  type?: string = '';
  price?: number = 0;
  therapist?: any;
  is_public?: boolean = false;
  patient?: any;
  event_ticket?: any;
  creatorHost?: string = 'placeholder';
  host?: any;
  url_event: string = "";
  url_thumbnail: string = "";
  user?: any;
  guests_users_id?: string[] | null = null;
  guests_email?: any;

  static convertToProduct(input: Event): SetProductDto {
    let output: SetProductDto = {
      produto_name: input.title,
      thumbnail: input.url_thumbnail,
      description: input.description!,
      value: input.price,
      id: input.id,
      type: input.type,
      url_thumbnail: input.url_thumbnail,
      itemType: 'event',
    };
    return output;
  }
}
