import {Component, OnInit} from '@angular/core';
import {Media} from "../../models/media";
import {MediaAdminService} from "../../services/media-admin.service";
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { MediaFolderService } from '../../services/media-folder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Folder } from '../../models/folder';

@Component({
  selector: 'media-admin',
  templateUrl: './media-admin.component.html',
  styleUrls: ['./media-admin.component.scss'],
  styles: [`
  :host ::ng-deep .p-dialog .product-image {
      width: 150px;
      margin: 0 auto 2rem auto;
      display: block;
  }
`],
providers: [MessageService]

})
export class MediaAdminComponent implements OnInit {
  constructor(
    protected readonly mediaAdminservice :MediaAdminService,
    private messageService: MessageService,
    private mediaFolderService: MediaFolderService,
    private primengConfig: PrimeNGConfig,
    private route: ActivatedRoute,
    private router: Router) {}

  currentFolderId = "";
  currentFolder: any;
  previousFolder = "";
  isModalVisible: boolean = false;
  showRegress: boolean = false;
  downloadingFile: boolean = false;
  showAddFolderModal: boolean = false;
  folderEditId!: number
  showEditFolderModal: boolean = false;
  action: string = "";
  // ngForConcluido: boolean = false
  selectedId: number = 0;
  take: number = 10;
  skip: number = 0;
  paginationPages: number[] = [];
  protected readonly Object = Object;
  loadingRemoveAction: boolean = false;
  isLoadingMedia: boolean = false;
  isGeneralErrorDisplayed: boolean = false;
  mediaUrl: any= "";
  sendingFileLoading: boolean = false;
  newMedia: any = {
    id: 1,
    userId: 1,
    title: "titulo 1",
    media_type: "Vídeo",
    subtype: "Mono",
    thumbnail: "teste",
    file: "link",
    file_name: "teste.mp4",
    key: "key",
    size: 12123123,
    mime_type: "video/mp4",
    created_at: new Date(),
    updated_at: new Date()
  };

  menuItems = [
    {
      label: 'Criar nova pasta',
      icon: 'pi pi-folder',
      command: () => {
        this.showModalAddOrEditFolder();
      }
    }
  ];

  newFolder: Folder = {
    name: "",
    folder_id: "",
    motherFolderId: parseInt(this.currentFolderId),
    is_public: false,
    motherFolderName: ""
  };

  editFolder: Folder = {
    name: "",
    folder_id: "",
    motherFolderId: parseInt(this.currentFolderId),
    is_public: false,
    motherFolderName: ""
  };

  folders: Folder[] = []

  mediaFormat: any = {
    'Vídeo 2d': 'mp4',
    'Vídeo 360/3d': 'mp4',
    'Vídeo 360 Mono': 'mp4',
    'Foto 360': 'jpeg',
    'Foto 360/3d': 'jpeg',
    'Áudio': 'mp3'
  }

  keys = ["title" ,"media_type" ,"thumbnail" ,"file" ,"file_name", "size",
    "mime_type","created_at" ];

  adminKeys = ["id", "userId" ,"title" ,"media_type" ,"thumbnail" ,"file" ,"file_name" ,"key" ,"size",
  "mime_type","created_at", "updated_at" ];


  selectedMedia: Media = new Media();

  medias: Media[] =[]
  createOrModifyKeys = [ "userId" ,"title" ,"media_type" ,"thumbnail" ,"file" ,"file_name" ,"key" ,"size",
  "mime_type","created_at", "updated_at" ];

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const paramValue = params['folder'];

      if(paramValue == 'root'){
        this.mediaFolderService.getRoot().subscribe({
          next:(folder: any)=>{
            this.currentFolderId = folder.data.id;
            this.currentFolder = folder.data;
            this.getFolders();
          },
          error: (err) => {
            if(err.status == 400 && err.error.message){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: err.error.message,
              });
            }else{
              if(!this.isGeneralErrorDisplayed){
                this.messageService.add({
                  key: 'notification',
                  severity: 'error',
                  summary: 'Houve um erro ao tentar obter as pastas',
                });
                this.isGeneralErrorDisplayed = true;
              }
            }
        }
        })
      }else{
        this.currentFolderId = paramValue;
        this.mediaFolderService.getOne(paramValue).subscribe({
          next:(folder: any)=>{
            this.currentFolder = folder.data;
            this.previousFolder = folder.data.motherFolderId;
            this.showRegress = false;
            if(folder.data.motherFolderId){
              this.showRegress = true;
            }
            this.getFolders();
          },
          error: (err) => {
            if(err.status == 400 && err.error.message){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: err.error.message,
              });
              this.router.navigateByUrl('/media-admin/root');
            }else{
              if(!this.isGeneralErrorDisplayed){
                this.messageService.add({
                  key: 'notification',
                  severity: 'error',
                  summary: 'Houve um erro ao tentar obter as pastas',
                });
                this.isGeneralErrorDisplayed = true;
              }
            }
        }
        })
      }

    });


    this.primengConfig.ripple = true;
    // this.mediaAdminservice.countPages(this.take).subscribe((result: any)=>{
    //   this.paginationPages = Array.from({ length: result.data }, (_, index) => index + 1);
    // });
    // this.loadMediaList();
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.ngForConcluido = true;
  //   }, 0);
  // }

  getFolders(){
    this.mediaFolderService.getAll(this.currentFolderId).subscribe({
      next: (result: any) =>{
        this.folders = result.data;
        this.loadMediaList();
      },
      error: (err:any)=>{
        if(err.status == 400 && err.error.message){
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: err.error.message,
          });
        }else{
          if(!this.isGeneralErrorDisplayed){
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: 'Houve um erro ao tentar obter as pastas',
            });
            this.isGeneralErrorDisplayed = true;
          }
        }
      }
    })
  }


  removeFolder(id: any){
    this.mediaFolderService.deleteOne(id).subscribe({
      next: (result: any) =>{
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: 'Pasta excluída com sucesso',
        });
        this.ngOnInit();
      },
      error: (err:any)=>{
        if(err.status == 400 && err.error.message){
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: err.error.message,
          });
        }else{
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: 'Houve um erro ao tentar apagar a pasta',
          });
        }
      }
    })
  }

  navigateToFolder(id?: any){
    if(!id){
      this.router.navigateByUrl('/media-admin/' + this.previousFolder);
    }else{
      this.router.navigateByUrl('/media-admin/' + id);
    }
  }

  pagination(skip: number){
    this.skip = skip;
    this.mediaAdminservice.getAll(this.take, this.skip, this.currentFolderId).subscribe((result: any)=>{
      this.medias = result.data;
    });
  }

  loadMediaList(){
    this.isLoadingMedia = true;
    this.mediaAdminservice.getAll(this.take, this.skip, this.currentFolderId).subscribe(
      {
        next: (result: any) => {
          this.medias = result.data;
          this.isLoadingMedia = false;
        },
        error: (err) => {
          if(err.status == 400 && err.error.message){
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: err.error.message,
            });
          }else{
            if(!this.isGeneralErrorDisplayed){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: 'Houve um erro ao tentar obter as mídias',
              });
              this.isGeneralErrorDisplayed = true;
            }
          }
      }
    })
}

  showModal(id: number, action: string){
    if(id != 0){
      const foundMedia = this.medias.find(media=> id == media.id);
      if(foundMedia){
        this.selectedMedia = foundMedia;
      }
    }
    this.action = action;
    this.isModalVisible = true;
    this.selectedId = id;
  }

  showModalAddOrEditFolder(id?: number){
    if(id){
      this.mediaFolderService.getOne(id).subscribe({
        next:(folder: any)=>{
          this.editFolder = folder.data;
          this.showEditFolderModal = true;
        },
        error: (err) => {
          if(err.status == 400 && err.error.message){
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: err.error.message,
            });
          }else{
            if(!this.isGeneralErrorDisplayed){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: 'Houve um erro ao tentar obter os dados da pasta',
              });
              this.isGeneralErrorDisplayed = true;
            }
          }
      }
      })

    }else{
      this.showAddFolderModal = true;
    }
  }



  closeModal(){
    this.isModalVisible = false;
    this.showEditFolderModal = false;
    this.showAddFolderModal = false;
    this.loadMediaList();
  }

  sendToAws(newMedia: Media){
    let type = this.mediaFormat[newMedia.media_type];
    return new Promise<void>((resolve, reject) => {
      this.mediaAdminservice.getUploadVideoUrl(type, newMedia.size).subscribe({
        next: (result: any)=>{
          this.mediaUrl = result.data.url.split("?")[0];
          newMedia.file_key = result.data.key;

          this.mediaAdminservice.uploadVideoToAws(newMedia.file, result.data.url).subscribe({
            next: (result: any)=>{
              newMedia.file = this.mediaUrl;
              resolve(this.mediaUrl);
            },
            error: (err) => {
              this.sendingFileLoading = false;
              this.closeModal();
              reject(err)
              this.closeModal();
              if(err.status == 400 && err.error.message){
                this.messageService.add({
                  key: 'notification',
                  severity: 'error',
                  summary: err.error.message,
                });
              }else{
                this.messageService.add({
                  key: 'notification',
                  severity: 'error',
                  summary: 'Houve um erro ao tentar adicionar a mídia',
                })
              }

            }
          }
          )
        },
        error: (err) => {
          this.sendingFileLoading = false;
          this.closeModal();
          reject(err)
          this.closeModal();
          if(err.status == 400){
            console.log(err)
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: err.error.message,
            })
          }else{
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: 'Houve um erro ao tentar adicionar a mídia',
            })
          }
        }
      })
    });

  }

  removeMedia(){
    this.loadingRemoveAction = true;
    this.mediaAdminservice.deleteOne(this.selectedId).subscribe({
      next: (result) => {
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: 'mídia excluída com sucesso',
        })
        this.closeModal();
        this.loadMediaList();
      },
      error: (err) => {
        this.closeModal();
        if(err.status == 400 && err.error.message){
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: err.error.message,
          });
        }else{
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: 'Houve um erro ao tentar excluir a mídia',
          })
        }

      }
    });
    this.loadingRemoveAction = false;
  }

  createFolder(){
    this.newFolder.motherFolderId = parseInt(this.currentFolderId);
    this.newFolder.motherFolderName = this.currentFolder.name;
    this.mediaFolderService.create(this.newFolder).subscribe({
      next: (result) => {
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: 'pasta criada com sucesso!',
        })
        this.closeModal();
        this.ngOnInit();
      },
      error: (err) => {
        this.closeModal();
        if(err.status == 400 && err.error.message){
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: err.error.message,
          });
        }else{
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: 'Houve um erro ao tentar criar a pasta',
          })
        }

      }
    });
  }

  updateFolder(){
    if(!this.editFolder.id){
      this.closeModal();
      this.ngOnInit();
      return
    }
    this.mediaFolderService.update(this.editFolder.id, this.editFolder).subscribe({
      next: (result) => {
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: 'pasta editada com sucesso!',
        })
        this.closeModal();
        this.ngOnInit();
      },
      error: (err) => {
        this.closeModal();
        if(err.status == 400 && err.error.message){
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: err.error.message,
          });
        }else{
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: 'Houve um erro ao tentar editar a pasta',
          })
        }

      }
    });
  }

  editMedia(newMedia: Media){
    this.sendingFileLoading = true;
    if(!(typeof newMedia.file === "string")){
      this.sendToAws(newMedia).then(()=>{
        newMedia['folder_id'] = this.currentFolderId;
        this.mediaAdminservice.update(newMedia.id, newMedia, newMedia.thumbnail).subscribe({
          next: data=>{
            if(data){
              this.sendingFileLoading = false;
              this.closeModal();
              this.loadMediaList();
              this.messageService.add({
                key: 'notification',
                severity: 'success',
                summary: 'Mídia Alterada com sucesso!',
              });
            }
            // this.modalClosed.emit('editSuccess');
          },
          error: (err) =>{
            this.sendingFileLoading = false;
            this.closeModal();
            if(err.status == 400 && err.error.message){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: err.error.message,
              });
            }else{
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: 'Houve um erro ao tentar editar a mídia',
              })
            }
          }
        });
      })
    }else{
        newMedia['folder_id'] = this.currentFolderId;
        this.mediaAdminservice.update(newMedia.id, newMedia, newMedia.thumbnail).subscribe({
          next: data=>{
            if(data){
              this.sendingFileLoading = false;
              this.closeModal();
              this.loadMediaList();
              this.messageService.add({
                key: 'notification',
                severity: 'success',
                summary: 'Mídia Alterada com sucesso!',
              });
            }
            // this.modalClosed.emit('editSuccess');
          },
          error: (err) =>{
            this.sendingFileLoading = false;
            this.closeModal();
            if(err.status == 400 && err.error.message){
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: err.error.message,
              });
            }else{
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: 'Houve um erro ao tentar editar a mídia',
              })
            }

          }
        });
    }

  }

  addMedia(newMedia: Media){
    this.sendingFileLoading = true;
    this.sendToAws(newMedia).then(()=>{
      newMedia['folder_id'] = this.currentFolderId;
      this.mediaAdminservice.create(newMedia, newMedia.thumbnail).subscribe({
        next: data=>{
          if(data){
            this.sendingFileLoading = false;
            this.closeModal();
            this.loadMediaList();
            this.messageService.add({
              key: 'notification',
              severity: 'success',
              summary: 'Mídia Adicionada com sucesso!',
            });
          }
          // this.modalClosed.emit('editSuccess');
        },
        error: (err) =>{
          this.sendingFileLoading = false;
          this.closeModal();
          if(err.status == 400 && err.error.message){
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: err.error.message,
            });
          }else{
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: 'Houve um erro ao tentar adicionar a mídia',
            })
          }
        }
      });
    })

  }

  proceedAction(actionData: {action: any, newMedia?: Media}){
    // this.closeModal();

    if(actionData.action == 'remove'){
      this.removeMedia();
    }

    if(actionData.action == 'edit' && actionData.newMedia){
      this.editMedia(actionData.newMedia);
    }

    if(actionData.action == 'add' && actionData.newMedia){
      this.addMedia(actionData.newMedia);
    }

  }

  transform(bytes: number): string {
    if (bytes === 0) {
      return '0 MB';
    }

    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2) + ' MB';
  }

  downloadFile(fileURL: string): void {
    this.downloadingFile = true;
    fetch(fileURL)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'arquivo'; // Defina o nome do arquivo a ser baixado
        a.click();
        URL.revokeObjectURL(url);
        this.downloadingFile = false;
      })
      .catch(error => {
      });
  }

  showDropDown(){
    alert('dropdown')
  }

}

