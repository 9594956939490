import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { GuestGuard } from 'src/app/guards/guest.guard';
import { UseTermsComponent } from './use-terms/use-terms.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ForgotPasswdComponent } from './forgot-passwd/forgot-passwd.component';
import { LandingPagePrototypeComponent } from './landing-page-prototype/landing-page-prototype.component';
import { DashboardComponent } from '../guest/dashboard/dashboard.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'terms',
    component: UseTermsComponent,
  },
  {
    path: 'email-confirmation/:token',
    component: EmailConfirmationComponent,
  },
  {
    path: 'reset-password/:token',
    component: PasswordResetComponent,
  },
  {
    path: 'sign-up',
    component: LandingPagePrototypeComponent,
  },
  {
    path: 'forgot-passwd',
    component: ForgotPasswdComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
