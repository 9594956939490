import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/models/event';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  @Input() event: Event = new Event();
  isLoading: boolean = false;
  eventId: any;
  userId: any;
  user: any;

  isModalOpen: boolean = false;
  isUserOrganizer: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private userService: UserDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadEvent();
  }

  loadEvent() {
    this.route.params.subscribe((result) => {
      if (result['eventId']) {
        this.eventId = result['eventId'];
        this.eventService.getEvent(this.eventId).subscribe((result: any) => {
          // console.log('result', result);
          this.event = result.data.evento[0];
          if (result.data.user.id) {
            this.userId = result.data.user.id;
            this.userService.getUserById(this.userId).subscribe((user: any) => {
              this.user = user.data;
              // console.log('user', user);
              this.isUserOrganizer = this.user && this.event.user.id === this.user.id;
            });
          }
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  onActionButtonClick() {
    if (this.isUserOrganizer) {
      this.cancelEvent(this.eventId);
      this.router.navigateByUrl('/app/' );
    }
  }

  cancelEvent(eventId: string) {
    this.eventService.delEvent(eventId).subscribe(
      (response: any) => {
        console.log('Evento excluído com sucesso', response);
      },
      (error: any) => {
        console.error('Erro ao excluir o evento', error);
      }
    );
  }

  goToEvents() {
    this.router.navigateByUrl('/');
  }

  openModal1() {
    this.isModalOpen = true;
  }
}









