import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })

  export class UserDataService {
    constructor(private http: HttpClient) {}

    baseUrl = 'user/'

    public getUserById(id:string){
        return this.http.get(environment.apiUrl + this.baseUrl +"getUser/"+ id);
    }

    public getAllUsers(){
        return this.http.get(environment.apiUrl + this.baseUrl);
    }

    public updateUser(body: any){
        return this.http.put(environment.apiUrl + this.baseUrl + 'updateUser/', body);
    }
  }