<modal [isModalOpen]="isModalOpen" (modalClosed)="modalClosed.emit()">
  <i class="pi pi-arrow-left back-to-event-icon" (click)="modalClosed.emit()"></i>
    <div class="pass-validation-wrapper">
      <svg-icon class="passwd-filled" name="user-research"></svg-icon>
      <div class="title">Digite o e-mail do usuário que deseja convidar:</div>
      <div class="passwd">
        <input
          class="input"
          placeholder="email@host.com"
          type="email"
          [(ngModel)]="email"
        />
      </div>
      <button class="primary-button" (click)="searchGuest()">Confirmar</button>
    </div>
  </modal>