import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SetProductDto } from 'src/app/models/DTOs/product-dto';
import { Event } from 'src/app/models/event';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class EventsComponent implements OnInit {
  searchField = '';
  userId: any;
  filter: any = {
    days_week: [0, 1, 2, 3, 4, 5, 6],
    event_type: 'myEvent',
    hosts: [],
    duration: 30,
    start: new Date('2000-01-01T10:00:00Z'),
    end: new Date('3000-01-01T10:00:00Z'),
    show_old_events: false,
  };

  isModalOpen1: boolean = false;
  loadingAllEvents = false;
  loadingMyEvents = false;

  weekDays: any[] = [
    { value: 1, title: 'Segunda-feira' },
    { value: 2, title: 'Terça-feira' },
    { value: 3, title: 'Quarta-feira' },
    { value: 4, title: 'Quinta-feira' },
    { value: 5, title: 'Sexta-feira' },
    { value: 6, title: 'Sábado' },
    { value: 0, title: 'Domingo' },
  ];
  eventTypes = [
    {
      title: 'Webinar',
      value: 'webinar',
    },
    {
      title: 'Meetup',
      value: 'meetup',
    },
    {
      title: 'Seminário',
      value: 'seminar',
    },
    {
      title: 'Aula/Curso',
      value: 'class',
    },
    {
      title: 'Meetups',
      value: 'meetups',
    },
    {
      title: 'Congresso',
      value: 'congress',
    },
    {
      title: 'Feiras de eventos', //master only
      value: 'fair',
    },
    {
      title: 'Outro',
      value: 'myEvent',
    },
  ];
  isShowFilterModal: boolean = false;

  constructor(
    private eventService: EventService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  allEventList: Event[] = [];
  invitedEventList: Event[] = [];
  myEventList: Event[] = [];
  allHostsList: any[] = [];

  ngOnInit(): void {
    this.userId = this.authService.getUserId();

    this.loadEvents();
  }

  loadEvents() {
   
    this.userService.getHosts().subscribe((result: any) => {
      this.allHostsList = result.data;
      this.filter.hosts = JSON.parse(
        JSON.stringify(this.allHostsList.map((host) => host.id))
      );
    });

    this.loadingAllEvents = true;
    this.eventService.getAllEvents().subscribe((result: any) => {
      result.data.eventos.forEach((element: Event) => {
        this.allEventList.push(element);
      });

      this.loadingAllEvents = false;

      this.myEventList = this.allEventList.filter(
        (event: any) => event.is_public == true
      );

      this.myEventList = JSON.parse(JSON.stringify(this.allEventList));
    });

    this.eventService.getAllInvites().subscribe((result: any) => {
      result.data.forEach((element: any) => {
        this.invitedEventList.push(element.event);
      });

      this.invitedEventList = JSON.parse(JSON.stringify(this.invitedEventList));
    });

    this.loadingMyEvents = true;
    this.eventService.getMyEvents(this.userId).subscribe(
      (eventsFromApi: any) => {
        console.dir('eventsFromApi', eventsFromApi);
        this.myEventList = eventsFromApi.data.eventos;
        this.loadingMyEvents = false;
      },
      (error) => {
        this.myEventList = [];
      }
    );
  }

  openEventDetails(event: Event) {
    this.router.navigateByUrl('/event/' + event.id);
  }

  subscribeToEvent(event: Event) {
    const cartKey = 'cart_event_' + event.id;

    if (!localStorage.getItem(cartKey)){
      const setProductDto = Event.convertToProduct(event);
      const carItem = {
        ...setProductDto,
    }   
      localStorage.setItem(cartKey, JSON.stringify(carItem));
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Produto adicionado ao carrinho!',   
      });
      this.router.navigateByUrl('/app/cart');
    }

  }

  onSearchFieldChange() {
    this.myEventList = JSON.parse(JSON.stringify(this.allEventList));

    if (this.filter) {
      this.eventService
        .getFilteredEvents(this.filter)
        .subscribe((result: any) => {
          this.myEventList = result.data;
        });
    }

    if (this.searchField) {
      this.myEventList = this.myEventList.filter(
        (event) =>
          event.title!.toLowerCase().indexOf(this.searchField.toLowerCase()) >
            -1 ||
          event.description!.indexOf(this.searchField.toLowerCase()) > -1
      );
    }
  }

  searchEvents() {
    this.onSearchFieldChange();
  }
  openModal1() {
    this.isModalOpen1 = true;
  }

   openNext() {
    this.tabIndex = 0;
  }
  tabIndex: number = 0;
}
