import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input() isReadOnly: boolean = true;
  selectedGender: any;
  selectedPlan: any;
  birthday: any;
  isHost: boolean = false;

  genders: any = [{
    title:'Outro',
    value:'other'
  }, {
    title:'Masculino',
    value:'male',
  }, {
    title:'Feminino',
    value:'female',
  }];

  plans: any = [{
    title:'Basic',
    value:'basic'
  }, {
    title:'Gold',
    value:'gold',
  }, {
    title:'Platinum',
    value:'platinum',
  }];


  constructor() { }

  ngOnInit(): void {
  }

}
