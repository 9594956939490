
<p-toast position="bottom-right"></p-toast>

<p-confirmDialog class="confirmDialog" #cd [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
      <h3>Senha trocada</h3>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Ok"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>

  <app-pass-validation
   *ngIf="isValidationModalOpen"
   [choosenPassword]="passwd"
   (modalClosed)="isValidationModalOpen = false"
   (onPasswdSet)="getPassConfirmationResult($event)">
</app-pass-validation>
<div class="forgot-passwd-wrapper">
<svg-icon class="svg-icon" name="forgot-passwd" ></svg-icon>
  <div class="text">Insira sua nova senha:</div>
  <div class="email-input">
    <input class="input" [(ngModel)]="passwd" placeholder="******" type="password" />
  </div>
  <button (click)="isValidationModalOpen = true" class="primary-button">Ok</button>

  <div class="logo">
    <img src="/assets/layout/images/logo-azul-scuro.png" alt="">
  </div>
</div>
