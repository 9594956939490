import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaFolderService {

  baseUrl = 'folder';
  constructor(private http: HttpClient) {}

  create(body: any) {
    return this.http.post(environment.apiUrl + this.baseUrl, body);
  }

  getRoot() {
    return this.http.get(environment.apiUrl + this.baseUrl + '/root');
  }

  getAll(motherFolderId: any) {
    return this.http.get(environment.apiUrl + this.baseUrl + '/mother/' + motherFolderId);
  }
  getOne(id: any) {
    return this.http.get(environment.apiUrl + this.baseUrl + '/' + id);
  }

  update(id: number, body: any ) {
    return this.http.put(environment.apiUrl + this.baseUrl + '/' + id, body);
  }

  deleteOne(id: number) {
    return this.http.delete(environment.apiUrl + this.baseUrl + '/' + id);
  }

}
