import { User, UserType } from './../models/user';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUser(id: string) {
    return this.http.get(environment.apiUrl + 'user/getUser/' + id);
  }

  public getAllUsers(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'user/getUsers?profile=guest');
  }

  public getHosts() {
    return this.http.get(environment.apiUrl + 'user/getUsers?profile=host');
  }

  public checkUserEmail(email: string) {
    return this.http.get(environment.apiUrl + 'user/check-email?email='+ email);
  }

  public addUser(user: User) {
    user.profiles = ['guest'];
    user.registrationDate = new Date();
    user.active = true;

    let profileBackgroundFormat = '';
    let profileImgFormat = '';
    if (user.profileBackground?.extension) {
      profileBackgroundFormat = JSON.parse(
        JSON.stringify(user.profileBackground?.extension)
      );
      user.profileBackground = user.profileBackground.file;
      user.profileBackgroundFormat = profileBackgroundFormat;
    }
    if (user.profileImg?.extension) {
      profileImgFormat = JSON.parse(JSON.stringify(user.profileImg?.extension));
      user.profileImg = user.profileImg.file;
      user.profileImgFormat = profileImgFormat;
    }

    return this.http.post(environment.apiUrl + 'user/newUser/', user);
  }

  public getHostByEmail(email: string) {
    return this.http.get(environment.apiUrl + 'user/getUser?email=' + email);
  }

  public updateUser(user: any) {
    return this.http.put(environment.apiUrl + 'user/updateUser', user);
  }

  public removeUser(id: number) {
    return this.http.delete(environment.apiUrl + 'user/deleteUser/' + id);
  }

  public getCustomerForUser(cpf: string){
    return this.http.get(environment.paymentApiUrl + '/customers/' + cpf);
  }
}
