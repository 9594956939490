import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SetProductDto } from 'src/app/models/DTOs/product-dto';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrls: ['./edit-product-modal.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class EditProductModalComponent implements OnInit {
  @Output()
  modalClosed: EventEmitter<any> = new EventEmitter<any>();

  @Input() isModalOpen: boolean = true;

  @Input() isReadOnly: boolean = false;

  @Input() isEditMode: boolean = false;

  product: SetProductDto = new SetProductDto();

  @Input() inputProduct: any;


  productTypes = [
    {
      title: 'Virtual',
      value: 'virtual',
    },
    {
      title: 'Físico',
      value: 'physical',
    },
  ];

  selectedProductType: any;

  activeIndex: number = 0;
  productPicturesVet: any[] = [];
  isLoading: boolean = false;

  constructor(
    private productService: ProductService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {}

  async ngOnInit() {
    await this.setEditMode();
  }

  imageClick(index: number) {
    this.activeIndex = index;
  }

  getProductPics(event: any) {
    this.product.thumbnail = event[0];
    this.product.url_thumbnail = event[0].path;
  }

  async setEditMode() {
    this.isEditMode = this.inputProduct;

    if (this.isEditMode) {
      this.product = JSON.parse(JSON.stringify(this.inputProduct))
      if (this.product.url_thumbnail || this.product.thumbnail) {
        this.product.thumbnail = await this.getBase64FromUrl(this.product.url_thumbnail ? this.product.url_thumbnail : this.product.thumbnail)
      }
    }
  }

  async getBase64FromUrl (url: any) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  saveProduct() {
    this.isLoading = true;
    if (this.isEditMode) {
      this.productService.updateProduct(this.product).subscribe((result: any ) => {
        console.log('edited');
        this.confirmationService.confirm({
          message: 'Produto editado com sucesso!',
          header: 'Sucesso!',
          icon: 'pi pi-check',
          accept: () => {
            window.location.reload();
            this.isModalOpen = false;
            this.modalClosed.emit();
          },
        });
      },(error: any) => {
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: 'Houve um erro ao tentar editar o produto',
        });
      })
    } if(!this.isEditMode){
      this.productService.addProduct(this.product).subscribe((result: any) => {
        console.log('created');
          this.confirmationService.confirm({
            message: 'Produto salvo com sucesso!',
            header: 'Sucesso!',
            icon: 'pi pi-check',
            accept: () => {
              window.location.reload();
              this.isModalOpen = false;
              this.modalClosed.emit();
            },
          });
        },(error) => {
          this.messageService.add({
            key: 'notification',
            severity: 'error',
            summary: 'Houve um erro ao tentar salvar o produto',
          });
        });
    }
  }
  deleteProduct(){
    console.dir ('this.product', this.product)
    this.isLoading = true;
    this.productService.deleteProduct(this.product.id).subscribe(
      (result: any) => {
          console.log('deleted');
          // Update view or state
          this.isModalOpen = false;
          this.modalClosed.emit(true);
      }
    )
  }
}
