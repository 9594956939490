<p-galleria
  *ngIf="isModalOpen"
  [(visible)]="isModalOpen"
  [value]="placeholder"
  [responsiveOptions]="responsiveOptions"
  [containerStyle]="{ 'max-width': maxWidth }"
  [numVisible]="7"
  [circular]="false"
  [fullScreen]="true"
  [showItemNavigators]="false"
  [showThumbnails]="false"
  [baseZIndex]="1"
  (visibleChange)="modalClosed.emit()"
>
  <ng-template pTemplate="item" let-item>
    <ng-content></ng-content>
  </ng-template>
</p-galleria>

<p-toast position="bottom-right"></p-toast>
