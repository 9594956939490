<div class="user-form row">
  <div class="form-item col-md-5">
    <label for="name">
      Nome
    </label>
    <input id="name" [disabled]="isReadOnly" placeholder="Digite seu nome completo" type="text" pInputText>
  </div>


  <div class="form-item col-md-3">
    <label for="nickname" class="block">
      Apelido
    </label>
    <input id="nickname" [disabled]="isReadOnly" placeholder="Crie um apelido" type="username"
      aria-describedby="username2-help" class="ng-dirty" pInputText />
    <!-- class="ng-invalid " <small id="nickname" class="p-error block">Apelido não disponível.</small> -->
  </div>

  <div class="form-item col-md-4">
    <label for="cpf">
      CPF
    </label>
    <input id="cpf" [disabled]="isReadOnly" placeholder="Digite seu CPF" type="text" pInputText>
  </div>

  <div class="form-item col-md-3">
    <label for="gender" class="block">
      Genero
    </label>
    <p-dropdown [options]="genders" [disabled]="isReadOnly" [(ngModel)]="selectedGender"
      placeholder="Selecione o genero" [optionValue]="'value'" [optionLabel]="'title'"></p-dropdown>
  </div>

  <div class="form-item col-md-3">
    <label for="birthdate" class="block">
      Data de nascimento
    </label>
    <div class="field">
      <p-calendar [disabled]="isReadOnly" id="birthdate" dateFormat="dd/mm/yy" placeholder="dd/mm/aaaa" [(ngModel)]="birthday"
        [showIcon]="true" inputId="icon"></p-calendar>
    </div>
  </div>

  <div class="form-item col-md-6">
    <label for="email1">Email</label>
    <input id="email1" [disabled]="isReadOnly" type="text" placeholder="email@exemplo.com" pInputText>
  </div>

  <div class="form-item col-md-5">
    <label for="password1">Senha</label>
    <input id="password1" [disabled]="isReadOnly" placeholder="Crie uma senha" type="password" placeholder="Senha"
      pInputText>
  </div>

  <div class="form-item col-md-4" *ngIf="isHost">
    <label for="plan" class="block">Plano</label>
    <p-dropdown [options]="plans" [disabled]="isReadOnly" [(ngModel)]="selectedPlan" placeholder="Selecione o plano"
      [optionValue]="'value'" [optionLabel]="'title'"></p-dropdown>
  </div>

  <div class="save">
    <button type="button" (click)="isReadOnly=true" class="btn btn-primary">Salvar</button>
  </div>


</div>
