<p-confirmDialog class="confirmDialog" #cd [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <h3>Cadastro realizado</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Ok"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>

<app-pass-validation
  *ngIf="isValidationModalOpen"
  [choosenPassword]="user.password"
  [isEditing]="isEditing"
  (onPasswdSet)="saveData($event)"
  (modalClosed)="isValidationModalOpen = false"
>
</app-pass-validation>

<modal
  [maxWidth]="'100rem'"
  [isModalOpen]="isModalOpen2"
  (modalClosed)="modalClosed2.emit()"
>
  <div class="user-profile-wrapper">
    <div class="profile-banner">
      <draggable-input
        *ngIf="isEditing"
        (onFilesUploaded)="getBannerPic($event)"
        [filesVet]="[{ file: user.profileBackground }]"
        [isOnlyPictures]="true"
      ></draggable-input>
      <draggable-input
        *ngIf="!isEditing"
        (onFilesUploaded)="getBannerPic($event)"
        [inputMessage]="'Escolha a capa ou solte-a aqui.'"
        [isOnlyPictures]="true"
      ></draggable-input>
    </div>
    <div class="profile-pic">
      <draggable-input
        *ngIf="isEditing"
        (onFilesUploaded)="getProfilePic($event)"
        [filesVet]="[{ file: user.profileImg }]"
        [isOnlyPictures]="true"
      ></draggable-input>
      <draggable-input
        *ngIf="!isEditing"
        (onFilesUploaded)="getProfilePic($event)"
        [inputMessage]="'Escolha a foto de perfil ou solte-a aqui.'"
        [isOnlyPictures]="true"
      ></draggable-input>
    </div>
    <div class="form-wrapper">
      <div class="row first-row">
        <div class="form-item col-xs-12 col-md-3">
          <label for="name"> Nome </label>
          <input
            id="name"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite seu nome"
            type="text"
            [(ngModel)]="user.name"
            [class.error]="errorDic['user.name']"
          
            [class.error]="errorDic[user.name]"
            pInputText
          />
        </div>
        <div class="form-item col-xs-12 col-md-3">
          <label for="surname"> Sobrenome </label>
          <input
            id="surname"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite seu sobrenome"
            type="text"
            [(ngModel)]="user.surname"
            [class.error]="errorDic['user.surname']"
            pInputText
          />
        </div>

        <div class="form-item col-xs-12 col-md-3">
          <label for="nickname" class="block"> Apelido </label>
          <input
            id="nickname"
            [disabled]="isReadOnly"
            placeholder="Crie um apelido"
            type="username"
            aria-describedby="username2-help"
            class="ng-dirty input"
            [(ngModel)]="user.nickname"
            [class.error]="errorDic['user.nickname']"
            pInputText
          />
          <!-- class="ng-invalid " <small id="nickname" class="p-error block">Apelido não disponível.</small> -->
        </div>
        <div class="form-item col-xs-12 col-md-3">
          <label for="specialty"> Especialidade </label>
          <input
            id="specialty"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite sua especialidade..."
            type="text"
            pInputText
            [(ngModel)]="user.specialty"
            [class.error]="errorDic['user.specialty']"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-item col-xs-12 col-md-2">
          <label for="birthdate" class="block"> Data de nascimento </label>
          <div class="field">
            <p-calendar
              [disabled]="isReadOnly"
              id="birthdate"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              inputId="icon"
              class="input"
              [(ngModel)]="user.birthday"
              [class.error]="errorDic['user.birthday']"
            ></p-calendar>
          </div>
        </div>

        <div class="form-item col-xs-12 col-md-2">
          <label for="cpf"> CPF </label>
          <input
            id="cpf"
            class="input"
            [disabled]="isReadOnly"
            (blur)="validateCPF()"
            (ngModelChange)="validateCPF()"
            [(ngModel)]="user.cpf"
            [class.error]="errorDic['user.cpf']"
            placeholder="000.000.000-00"
            maxlength="14"
          />
        </div>

        <div class="form-item col-xs-12 col-md-3">
          <label for="phoneNumber"> Telefone </label>
          <input
            id="phoneNumber"
            class="input"
            [disabled]="isReadOnly"
            placeholder="(00) 00000-0000"
            type="text"
            maxlength="11"
            mask="(00) 00000-0000"
            [(ngModel)]="user.phoneNumber"
            [class.error]="errorDic['user.phoneNumber']"
          />
        </div>

        <div class="form-item col-xs-12 col-md-3">
          <label for="email">Email</label>
          <input
            class="input"
            id="email"
            [disabled]="isReadOnly"
            type="text"
            placeholder="email@exemplo.com"
            [(ngModel)]="user.email"
            [class.error]="errorDic['user.email']"
            pInputText
          />
        </div>
        <div class="form-item col-xs-12 col-md-2">
          <label for="gender"> Genero </label>
          <p-dropdown
            id="gender"
            [options]="genders"
            [disabled]="isReadOnly"
            [(ngModel)]="user.gender"
            [class.error]="errorDic['user.gender']"
            placeholder="Selecione o genero"
            [optionValue]="'value'"
            [optionLabel]="'title'"
          ></p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="form-item col-xs-12 col-md-3">
          <label for="cep"> CEP </label>
          <input
            id="cep"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite seu CEP"
            type="text"
            pInputText
            placeholder="00000-000"
            mask="00000-000"
            [(ngModel)]="user.address.cep"
            [class.error]="errorDic['user.address.cep']"
            (blur)="getCep($event)"
          />
        </div>
      
        <div class="form-item col-xs-12 col-md-3">
          <label for="state">Estado</label>
          <p-dropdown
            id="state"
            [disabled]="isReadOnly"
            [options]="states"
            [optionValue]="'value'"
            [optionLabel]="'title'"
            [(ngModel)]="user.address.state"
            [class.error]="errorDic['user.address.state']"
            placeholder="Selecione um estado..."
            (ngModelChange)="fetchCities()"
          >
          </p-dropdown>
        </div>
        <div class="form-item col-xs-12 col-md-3">
          <label for="city">Cidade</label>
          <p-dropdown
            id="city"
            [disabled]="isReadOnly"
            [options]="cities"
            [(ngModel)]="user.address.city"
            [class.error]="errorDic['user.address.city']"
            placeholder="Selecione uma cidade..."
          >
          </p-dropdown>
        </div>
        <div class="form-item col-xs-12 col-md-3">
          <label for="neighborhood"> Bairro </label>
          <input
            id="neighborhood"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite seu Bairro"
            type="text"
            pInputText
            [(ngModel)]="user.address!.neighborhood"
            [class.error]="errorDic['user.address.neighborhood']"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-item col-xs-12 col-md-3">
          <label for="country">País</label>
          <p-dropdown
            id="country"
            (ngModelChange)="fetchCities()"
            [disabled]="isReadOnly"
            [options]="countries"
            [optionValue]="'value'"
            [optionLabel]="'title'"
            [(ngModel)]="user.address.country"
            [class.error]="errorDic['user.address.country']"
          >
          </p-dropdown>
        </div>
        <div class="form-item col-xs-12 col-md-4">
          <label for="street"> Rua </label>
          <input
            id="street"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite sua rua..."
            type="text"
            pInputText
            [(ngModel)]="user.address.street"
            [class.error]="errorDic['user.address.street']"
          />
        </div>
        <div class="form-item col-xs-12 col-md-2">
          <label for="number"> Número: </label>
          <input
            id="number"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite o número"
            type="text"
            pInputText
            [(ngModel)]="user.address.number"
            [class.error]="errorDic['user.address.number']"
          />
        </div>
        <div class="form-item col-xs-12 col-md-3">
          <label for="complement"> Complemento </label>
          <input
            id="complement"
            class="input"
            [disabled]="isReadOnly"
            placeholder="Digite o complemento..."
            type="text"
            pInputText
            [(ngModel)]="user.address.complement"
            [class.error]="errorDic['user.address.complement']"
          />
        </div>
      </div>
      <div class="row">
        <div *ngIf="!isEditing" class="form-item col-xs-12 col-md-6 !important;">
          <label for="bio"> Descrição </label>
          <textarea
            id="bio"
            class="input"
            [disabled]="isReadOnly"
            [placeholder]="'Digite sua bio...'"
            pInputText
            [(ngModel)]="user.description"
            [class.error]="errorDic['user.description']"
          >
          </textarea>
        </div>
        <div *ngIf="isEditing" class="form-item col-xs-12 col-md-9 !important;">
          <label for="bio"> Descrição </label>
          <textarea
            id="bio"
            class="input"
            [disabled]="isReadOnly"
            [placeholder]="'Digite sua bio...'"
            pInputText
            [(ngModel)]="user.description"
            [class.error]="errorDic['user.description']"
          >
          </textarea>
        </div>
        <div class="form-item col-xs-12 col-md-3" *ngIf="!isEditing">
          <label for="original"> Escolha uma senha: </label>
          <input
            [(ngModel)]="user.password"
            [class.error]="errorDic['user.password']"
            class="input"
            id="original"
            placeholder="••••••"
            type="password"
            autocomplete="off"
            aria-autocomplete="off"
          />
        </div>
        <button class="col-xs-12 col-md-3 primary-button" (click)="saveUser()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</modal>
