<div>
  <div class="row form">

    <p-toast key="notification" position="top-right"></p-toast>
    <div class="form-item col-md-6">
      <label for="media-title">Título</label>
      <input class="input" id="media-title" type="text"
        placeholder="Digite o Título"
        [(ngModel)]="newMedia.title"
        required >
    </div>

    <div class="form-item col-md-6">
      <label for="media-type">Tipo de mídia</label>
      <p-dropdown  id="media-subtype" [options]="mediaTypes" [(ngModel)]="newMedia.media_type"
      placeholder="Selecione o tipo" id="media-type" ></p-dropdown>
    </div>

    <!-- <div class="form-item col-md-3" >
      <label for="media-subtype">Subtipo</label>
      <p-dropdown  id="media-subtype" [options]="subtypes" [(ngModel)]="newMedia.subtype"
      placeholder="Selecione o tipo" id="product-type" [disabled]="newMedia.media_type == 'Foto 360'"></p-dropdown>
    </div> -->

    <div class="col-md-6 draggable-area">
      <label for="media-thumbnail">Thumbnail</label>
      <svg-icon name="upload-unfilled"></svg-icon>
      <span>Selecione um arquivo de mídia ou arraste.</span>
      <div *ngIf="newMedia.thumbnail" class="uploaded-Media"><img class="media" [src]="thumbnailUrl"/></div>
        <input
          id="media-thumbnail"
          class="draggable-area"
          draggable="true"
          (change)="thumbnailUpload($event)"
          type="file"
          accept="image/*"
          class="fileupload"
        />
    </div>

    <div class="col-md-6 draggable-area">
      <label for="media-file">Arquivo de mídia</label>
      <svg-icon name="upload-unfilled"></svg-icon>
      <span *ngIf="!newMedia.media_type" >Só é possível anexar uma mídia depois de selecionar o tipo.</span>
      <span *ngIf="newMedia.media_type" >Selecione um arquivo de mídia ou arraste.</span>

      <div [class.hide]="!((newMedia.media_type == 'Vídeo 2d' || newMedia.media_type == 'Vídeo 360/3d' || newMedia.media_type == 'Vídeo 360 Mono' || newMedia.media_type  == 'Áudio') && fileUrl)" class="uploaded-Media">
        <video controls class="media">
          <source [src]="fileUrl" type="video/mp4"/>
          Seu navegador não suporta o elemento de vídeo.
        </video>
      </div>

      <div [class.hide]="!((newMedia.media_type == 'Foto 360' || newMedia.media_type == 'Foto 360/3d') && fileUrl)" class="uploaded-Media">
        <img class="media" [src]="fileUrl"/>
      </div>

      <!-- <div [class.hide]="!((newMedia.media_type == 'Áudio') && fileUrl)" class="uploaded-Media">
        <audio controls class="media-audio">
          <source [src]="fileUrl" type="audio/mpeg">
          Seu navegador não suporta o elemento de Áudio.
        </audio>
      </div> -->
        <input
          *ngIf="newMedia.media_type"
          id="media-file"
          class="draggable-area"
          draggable="true"
          (change)="mediaUpload($event)"
          type="file"
          accept="video/mp4 image/jpeg audio/mpeg"
          class="fileupload"
        />
    </div>

    <div class="buttons-group">
      <!-- <button pButton (click)="closeModal()" label="Cancelar" class="p-button-danger modal-buttons"></button> -->
      <button pButton (click)="proceedAction()" label="Salvar" [loading]="loading" class="p-button-success modal-buttons"></button>
    </div>
  </div>
</div>
