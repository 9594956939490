import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UseTermsComponent } from './use-terms/use-terms.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ForgotPasswdComponent } from './forgot-passwd/forgot-passwd.component';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { LandingPagePrototypeComponent } from './landing-page-prototype/landing-page-prototype.component';
import { FormsModule } from '@angular/forms';
import { PublicRoutingModule } from './public-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    UseTermsComponent,
    EmailConfirmationComponent,
    ForgotPasswdComponent,
    LoginComponent,
    LandingPagePrototypeComponent,
    PasswordResetComponent,
  ],
  imports: [
    CommonModule, //
    SharedModule, //
    FormsModule, //
    PublicRoutingModule, //
    DropdownModule, //
    GalleriaModule, //
    TabViewModule, //
    ConfirmDialogModule, //
    ToastModule, //
  ],
})
export class PublicModule {}
