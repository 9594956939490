<div class="login-wrapper">
  <img class="logo-primary" src="assets/images/logo-filled.svg " alt="" />
  <div class="underlayer"></div>
  <img
    src="assets/images/login-background.png"
    class="background"
    alt="background"
  />
 
  <div class="login-container">
    <img class="logo" src="assets/layout/images/logo-azul-scuro.png" />
    <h3 class="login-title">Bem vindo</h3>
    <div class="credentials-wrapper">
      <div class="email-wrapper cred-wrapper">
        <div class="icon-wrapper"><svg-icon name="mail"> </svg-icon></div>
        <input
          type="email"
          [(ngModel)]="inputData.email"
          class="input"
          name="email"
          placeholder="Digite seu E-mail"
          id="email"
        />
      </div>
      <div class="passwd-wrapper cred-wrapper">
        <div class="icon-wrapper"><svg-icon name="locker"> </svg-icon></div>
        <input
          [type]="viewPasswd ? 'text' : 'password'"
          name="passwd"
          class="input"
          [(ngModel)]="inputData.password"
          placeholder="Digite sua Senha"
          id="passwd"
        />
        <svg-icon
          (click)="viewPasswd = !viewPasswd"
          *ngIf="!viewPasswd"
          class="eye-icon"
          name="eye"
        >
        </svg-icon>
        <svg-icon
          (click)="viewPasswd = !viewPasswd"
          *ngIf="viewPasswd"
          class="eye-icon"
          name="eye-slashed"
        >
        </svg-icon>
      </div>
      <h5 class="fgt-passwd">
        <a href="/forgot-passwd">Esqueceu sua senha?</a>
      </h5>
    </div>
    <div class="mensagem-erro" *ngIf="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
    <button (click)="login()" class="primary-button">Entrar</button>
    <!--<div class="social-login">
      <button><svg-icon name=""></svg-icon></button>
      <button><svg-icon name=""></svg-icon></button>
    </div> -->

    <h5 class="registration">
      Não tem uma conta
      <div class="registration-link" (click)="isModalOpen2 = true">
        Cadastre-se aqui.
      </div>
    </h5>
  </div>

  <div class="landing-content">
    <div class="landing-text">
      <div class="subtitle">Sua Presença</div>
      <div class="title">
        No
        <div class="underlined">Metaverso</div>
      </div>
    </div>
    <div class="ad-content">
      <section>
        <img src="assets/images/icons/360.svg" alt="" />
        <div class="text-content">
          <div class="title">Vídeos 360</div>
          <div class="text-center description"> Ambientalize suas experiências no metaverso com gravações 360º do
          mundo real.
        </div>
        </div>
      </section>
      <div class="separator"></div>
      <section>
        <img src="assets/images/icons/network.svg" alt="" />
        <div class="text-content">
          <div class="title">Eventos</div>
          <div class="text-center description"> Traga para um mesmo espaço virtual a presença de pessoas de diferentes
          localidades
        </div>
        </div>
      </section>
      <div class="separator"></div>
      <section>
        <img src="assets/images/icons/touch.svg" alt="" />
        <div class="text-content">
          <div class="title">Interatividade</div>
        <div class="text-center description">  Experiências interativas preparadas para potencializar seus encontros
          em realidade virtual
        </div>
        </div>
      </section>
    </div>
  </div>
  <div class="social-networks">
    <a href="https://www.youtube.com/@AgooraVR/" class="youtube" target="_blank">
      <img src="assets/images/icons/youtube.svg" alt="" />
    </a>
    <a href="https://www.instagram.com/agooravr/" class="instagram" target="_blank">
      <img src="assets/images/icons/instagram.svg" alt="" />
    </a>
    <a href="https://wa.me/5511977930609" class="whatsapp" target="_blank">
      <img src="assets/images/icons/whatsapp.svg" alt="" />
    </a>
    <a
      href="mailto:agooravr@gmail.com?subject=Pr%C3%A9-inscri%C3%A7%C3%A3o%20Agoora&body=Ol%C3%A1,%20venho%20da%20sua%20p%C3%A1gina%20da%20Agoora."
      class="email" target="_blank"
    >
      <img src="assets/images/icons/email.svg" alt="" />
    </a>
  </div>
</div>

<app-user-profile-modal
  *ngIf="isModalOpen2"
  (modalClosed2)="isModalOpen2 = false"
  [isModalOpen2]="isModalOpen2"
>
</app-user-profile-modal>
