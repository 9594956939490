import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SetProductDto } from '../models/DTOs/product-dto';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  baseUrl = 'produtos';
  baseUrlAditional = 'aditional';

  constructor(private http: HttpClient, private authService: AuthService) {}

  public getAllAditionals() {
    return this.http.get(environment.apiUrl + this.baseUrlAditional);
  }

  getCurrentClinicData() {
    return this.http.get(environment.apiUrl + this.baseUrl + 'clinics');
  }

  public getProduct(id: string) {
    return this.http.get(environment.apiUrl + this.baseUrl + id);
  }

  public getAllProducts() {
    return this.http.get(environment.apiUrl + this.baseUrl + '/');
  }

  public addProduct(product: SetProductDto) {
    let realProduct: any = JSON.parse(JSON.stringify(product));

    realProduct.produto_name = realProduct.produto_name;

    if (this.authService.isMaster()) {
      realProduct.type = 'private';
    }

    var formData = new FormData();
    const file = realProduct.url_thumbnail;
    const fileContent = realProduct.thumbnail;

    delete realProduct.thumbnail;
    delete realProduct.url_thumbnail;
    delete realProduct.category;

    Object.keys(realProduct).forEach((key) => {
      formData.append(key, realProduct[key]);
    });

    if (fileContent) {
      formData.append('thumbnail', this.b64toBlob(fileContent.file), file);
    }

    return this.http.post(environment.apiUrl + this.baseUrl, formData);
  }

  private b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data.split('base64,')[1]);
    const byteArrays = [];

    console.dir('byteCharacters', byteCharacters);

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
      ///if (!this.b64toBlob) {
      //  return (null)
      //}
    }

    console.dir(`b64Data.split('base64,')[1]`, b64Data.split('base64,')[0]);

    const blob = new Blob(byteArrays, { type: b64Data.split('base64,')[0] });
    return blob;
  }

  public updateProduct(product: SetProductDto) {
    return new Observable<any>((subscriber) => {
      let realProduct: any = JSON.parse(JSON.stringify(product));
      realProduct.produto_name = realProduct.produto_name;
      realProduct.produto_id = realProduct.id

      if (this.authService.isMaster()) {
        realProduct.type = 'private';
      }

      var formData = new FormData();
      const file = realProduct.url_thumbnail ? realProduct.url_thumbnail : realProduct.thumbnail;

      const fileContent = realProduct.thumbnail;

      delete realProduct.thumbnail;
      delete realProduct.url_thumbnail;
      delete realProduct.category;

      realProduct.update = true;

      Object.keys(realProduct).forEach((key) => {
        formData.append(key, realProduct[key]);
      });

      console.dir('formData', formData)

      const isNewUpload = fileContent?.path;

      if (fileContent && !isNewUpload) { // Update with no new image
        this.fetchUrlAsBlob(fileContent, file).then((blob: any) => {
          formData.append('thumbnail', blob, 'test');
          this.http.put(environment.apiUrl + this.baseUrl, formData).subscribe((result) => {
            subscriber.next(result);
            subscriber.complete();
          });
        })

      }
      else if (fileContent && isNewUpload) {
        formData.append('thumbnail', this.b64toBlob(fileContent.file), file);
        this.http.put(environment.apiUrl + this.baseUrl, formData).subscribe((result) => {
          subscriber.next(result);
          subscriber.complete();
        });
      }
      else {
        this.http.put(environment.apiUrl + this.baseUrl, formData).subscribe((result) => {
          subscriber.next(result);
          subscriber.complete();
        });
      }
    });
  }

  private fetchUrlAsBlob(fileContent: any, file: any) {
    return new Promise((res,rej) => {
      try {
        console.dir('fileContent', fileContent);
        console.dir('file', file);
        fetch(fileContent).then((response) => {
          response.blob().then((blob) => {
            res(blob);
          })
        })
      }
      catch (error) {
        console.log('skipping probably unchanged thumbnail change');
      }
    })
  }
  public deleteProduct(id: string){
    return this.http.delete(environment.apiUrl + this.baseUrl + '/' + id);

  }

}
