<!-- <p-confirmDialog #cd [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <h3>Confirmar Senha</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Ok"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog> -->
<modal [isModalOpen]="isModalOpen" (modalClosed)="modalClosed.emit()">
  <div class="pass-validation-wrapper">
    <svg-icon class="passwd-filled" name="passwd-filled"></svg-icon>
    <div class="title">Digite sua senha para confirmar.</div>
    <div class="passwd">
      <input
        class="input"
        placeholder="**********"
        type="password"
        [(ngModel)]="repeatPassword"
      />
    </div>
    <button class="primary-button" (click)="validatePasswd()">Confirmar</button>
  </div>
</modal>
