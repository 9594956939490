import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CardService } from 'src/app/services/card.service';

@Component({
  selector: 'app-card-modal',
  templateUrl: './card-modal.component.html',
  styleUrls: ['./card-modal.component.scss']
})
export class CardModalComponent implements OnInit {

  @Output()
  cardAdded:EventEmitter<void> = new EventEmitter<void>();

  @Output()
  modalClosed:EventEmitter<void> = new EventEmitter<void>();

  creditCard: any = {};
  holderName: any = '';
  holderCpf: any = '';
  hasError: boolean = false;

  constructor(private cardService:CardService) { }

  ngOnInit(): void {
    this.creditCard.billing_address = {} as any;
    this.creditCard.billing_address.country = 'BR';
  }

  addCard() {
    this.hasError = false;
    this.cardService.addCard(this.creditCard).subscribe((result: any) => {
      this.cardAdded.next();
    },(error) => {
      this.hasError = true;
    })
  }

}
