import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaAdminService {

  baseUrl = 'media';
  constructor(private http: HttpClient) {}

  create(body: any, thumbnail: any) {
    const formData = new FormData();
    formData.append("info", JSON.stringify(body));
    formData.append("thumbnail", thumbnail);
    return this.http.post(environment.apiUrl + this.baseUrl, formData);
  }

  getOne(id: number) {
    return this.http.get(environment.apiUrl + this.baseUrl + '/' + id);
  }

  getAll(take: number, skip: number, currentFolderId: string) {
    return this.http.get(environment.apiUrl + this.baseUrl + '?folder_id=' + currentFolderId + '&take='+ take + '&skip=' + skip );
  }

  countPages(take: number) {
    return this.http.get(environment.apiUrl + this.baseUrl + '/pages?take='+ take );
  }

  update(id: number, body: any, thumbnail: any ) {
    const formData = new FormData();
    formData.append("info", JSON.stringify(body));
    if(!(typeof thumbnail === "string")){
      formData.append("thumbnail", thumbnail);
    }
    return this.http.put(environment.apiUrl + this.baseUrl + '/' + id, formData);
  }

  deleteOne(id: number) {
    return this.http.delete(environment.apiUrl + this.baseUrl + '/' + id);
  }

  getUploadVideoUrl(type: string, size: number) {
    return this.http.get(environment.apiUrl + this.baseUrl + '/generate/url/' + type + '?size=' + size );
  }

  uploadVideoToAws(file: any, url: string) {
    return this.http.put(url, file);
  }
}
