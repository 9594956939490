import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-passwd',
  templateUrl: './forgot-passwd.component.html',
  styleUrls: ['./forgot-passwd.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class ForgotPasswdComponent implements OnInit {
  email: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}
  sendResetPasswdEmail() {
    if (this.email.length < 1) return;

    this.authService.tryRecoverPassword(this.email.trim()).subscribe(
      (result: any) => {
        this.confirmationService.confirm({
          message:
            'Caso haja uma conta vinculada a este e-mail, em breve você receberá o link para resetar sua senha!',
          header: 'Sucesso!',
          icon: 'pi pi-check',
          accept: () => {
            this.router.navigateByUrl('/login');
          },
        });
      },
      (error: any) => {
        let message = 'Erro interno. Tente novamente mais tarde.';

        if (error.status == 400) {
          message = error.error?.message;
        }
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: error.error.message ?? error.error,
        });
      }
    );
  }
}
