import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeographyService {

  constructor(private http:HttpClient) { }

  getCitiesByUF(UF: any) {
    const protocol = document.location.protocol == 'https:' ? 'https' : 'http';
    return this.http.get(`${protocol}://servicodados.ibge.gov.br/api/v1/localidades/estados/`+UF.state+`/municipios`);
  }

  public getFullAddressByCep(cep: string) {
    if (document.location.protocol == 'https:')
      return this.http.get(
        'https://viacep.com.br/ws/' +
          cep.replace('.', '').replace('-', '') +
          '/json/'
      );
    else
      return this.http.get(
        'http://viacep.com.br/ws/' +
          cep.replace('.', '').replace('-', '') +
          '/json/'
      );
  }
}
