import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Media} from "../../../models/media";
import {MediaAdminService} from "../../../services/media-admin.service";
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'media-admin-form',
  templateUrl: './media-admin-form.component.html',
  styleUrls: ['./media-admin-form.component.scss'],
  providers: [MessageService]
})
export class MediaAdminFormComponent implements OnChanges {
  constructor(protected readonly mediaAdminservice :MediaAdminService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private sanitizer: DomSanitizer,
    private elRef: ElementRef) {}

  isModalVisible: boolean = false;
  selectedId: number = 0;
  subtypes = ['Mono', 'Stereo'];
  mediaTypes = ['Vídeo 2d', 'Vídeo 360/3d', 'Vídeo 360 Mono', 'Foto 360', 'Foto 360/3d', 'Áudio' ];

  protected readonly Object = Object;
  thumbnailUrl: any= "";
  fileUrl: any= "";
  mediaUrl: any= "";
  loading: boolean = false;
  uploadedNewMedia: boolean = false;

  @Output()
  proceedEventCalled: EventEmitter<{action: string, newMedia: Media}> = new EventEmitter();

  @Input() selectedMedia: Media = new Media();
  @Input() action: any;

  newMedia: Media = new Media();

  ngOnChanges(changes: SimpleChanges): void {
    this.primengConfig.ripple = true;
    Object.assign(this.newMedia, this.selectedMedia);
    if(this.selectedMedia.file){
      this.fileUrl = this.newMedia.file;
      const videoPlayer = this.elRef.nativeElement.querySelector('video');
      videoPlayer.load();
      // const audioPlayer = this.elRef.nativeElement.querySelector('audio');
      // audioPlayer.load();
    }
    if(this.selectedMedia.thumbnail){
      this.thumbnailUrl = this.newMedia.thumbnail;
    }
    // throw new Error('Method not implemented.');
  }

  mediaUpload(event: any): any {
    if (event.target.files) {
      if(event.target.files.length > 1){
        return false;
      }
      const file = event.target.files[0];
      this.newMedia.file = file
      this.newMedia.file_name = file.name;
      this.newMedia.size = file.size;
      this.uploadedNewMedia = true;
      this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      const player = this.elRef.nativeElement.querySelector('video');
      player.load();

      // const audioPlayer = this.elRef.nativeElement.querySelector('audio');
      // audioPlayer.load();
    };
  }


  thumbnailUpload(event: any): any{
    if (event.target.files) {
      if(event.target.files.length > 1){
        return false;
      }
      const file = event.target.files[0];
      this.newMedia.thumbnail = event.target.files[0]
      this.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    };
  }


  validateFields(){
    let valid = true;
    // if(this.newMedia.media_type != 'Foto 360' && !this.newMedia.subtype){
    //   valid = false;
    // }
    if(
    !this.newMedia.file ||
    !this.newMedia.file_name ||
    !this.newMedia.size ||
    !this.newMedia.thumbnail ||
    !this.newMedia.title ||
    !this.newMedia.media_type ||
    !this.newMedia.thumbnail ||
    !this.newMedia.file
    ){
      valid = false;
    }
    return valid;
  }

  proceedAction(){
    // if(this.newMedia.media_type != 'Vídeo 2d' && this.newMedia.media_type != 'Vídeo 360/3d'){
    //   this.newMedia.subtype = '';
    // }

    if(this.uploadedNewMedia){
      if((this.newMedia.media_type == 'Vídeo 2d' || this.newMedia.media_type == 'Vídeo 360/3d' || this.newMedia.media_type == 'Vídeo 360 Mono') && this.newMedia.file.type != 'video/mp4'){
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: 'Formato de arquivo inválido para o tipo selecionado',
        });
        return
      }

      if((this.newMedia.media_type == 'Foto 360/3d' || this.newMedia.media_type == 'Foto 360') && this.newMedia.file.type != 'image/jpeg'){
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: 'Formato de arquivo inválido para o tipo selecionado',
        });
        return
      }

      if(this.newMedia.media_type == 'Áudio' && this.newMedia.file.type != 'audio/mpeg'){
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: 'Formato de arquivo inválido para o tipo selecionado',
        });
        return
      }
    }

    const validate = this.validateFields();
    if(!validate){
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: 'Por favor preencha todos os campos para realizar o envio',
      });
      return;
    }
    this.proceedEventCalled.emit({action: this.action, newMedia: this.newMedia});
  }

}

