import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/models/user';
import { AuthService, Roles } from 'src/app/services/auth.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slideInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(":leave", [
        animate('200ms ease-out', style({ transform: 'translateX(100%)' })),
      ])
    ])
  ],
})
export class NavbarComponent implements OnInit {
  items: MenuItem[];
  userId: any;
  user: User = new User();
  defaultPicture: any = '/assets/images/card-profile-user.svg';
  profileImage = '/assets/images/card-profile-user.svg';
  isUserModalOpen = false;
  isGuest = this.authService.getRoles().indexOf(Roles.Guest) > -1;
  isHost = this.authService.getRoles().indexOf(Roles.Host) > -1;
  isMaster = this.authService.getRoles().indexOf(Roles.Master) > -1;
  xsMenuOpen = false;
  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private router: Router
  ) {
    this.items = [
      {
        label: 'Download Agoora VR',
      },
      {
        label: 'Entrar',
        url: 'entrar',
      },
      {
        label: 'Dashboard',
        url: 'home',
      },
    ];
  }

  ngOnInit() {
    this.userId = this.authService.getUserId();
    this.userDataService.getUserById(this.userId).subscribe((result: any) => {

      this.user = result.data;
      if (this.user) {
        if (!this.user.profileImg) {
          this.user.profileImg = this.defaultPicture;
          return;
        }

        this.profileImage = this.user.profileImg;
      }
    });

  }

  openMyProfile() {
    if (!this.isHost) {
      this.isUserModalOpen = true;
      return;
    }
    this.router.navigateByUrl('/hosts/my-panel');
  }

  toggleMenuXs() {
    this.xsMenuOpen = !this.xsMenuOpen;
  }

  logout() {
    this.authService.logOut();
  }
}
