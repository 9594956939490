import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'draggable-input',
  templateUrl: './draggable-input.component.html',
  styleUrls: ['./draggable-input.component.scss'],
})
export class DraggableInputComponent implements OnInit {
  @Output() public onFilesUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Input() maxFileQuantity: number = 1;
  @Input() allowedTypes: string = '*';
  @Input() inputMessage: string = 'Escolha os arquivos ou solte-os aqui.';
  @Input() isOnlyPictures: boolean = false;
  @Input() defaultPicture: any;
  @Input() currentEventImage: string = '';
  @Input() show1920: boolean = false;

  @Input() filesVet: any[] = [];

  disableUpload: boolean = false;

  @Input() initialImageUrl: string | undefined;

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    if (this.isOnlyPictures) {
      this.allowedTypes = 'BMP, TIFF, JPEG, JPG, GIF, PNG, PDF, EPS, SVG';
      this.allowedTypes = this.allowedTypes.toLocaleLowerCase();
    }
    
    if (this.currentEventImage) {
      this.filesVet.push({ file: this.currentEventImage });
    }

  }

  removePic(picture: any) {
    this.filesVet = this.filesVet.filter((pic: any) => pic !== picture);
    if (this.maxFileQuantity <= this.filesVet.length) {
      this.disableUpload = true;
    } else {
      this.disableUpload = false;
    }
    this.onFilesUploaded.emit(this.filesVet);
  }

  filesUploaded(originalEvent: any) {
    if (originalEvent.target.files) {
      Array.from(originalEvent.target.files).forEach((file: any) => {
        if (
          (originalEvent.target.files &&
            originalEvent.target.files.length > this.maxFileQuantity) ||
          this.filesVet.length >= this.maxFileQuantity
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail:
              'Você pode armazenar até ' + this.maxFileQuantity + ' arquivos.',
          });
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file); 

        reader.onload = (event) => {
  

          var arr = file.name.split('.');
          var fileExtension = arr[arr.length - 1];
          console.log("EXTENSAO", fileExtension);
          let auxAllowedTypes = JSON.parse(JSON.stringify(this.allowedTypes));

          if (
            this.allowedTypes != '*' &&
            !this.isOnlyPictures &&
            !(auxAllowedTypes.indexOf(fileExtension) > -1)
          ) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Formato de arquivo não suportado.',
            });
            return;
          } else if (this.isOnlyPictures && auxAllowedTypes.indexOf(fileExtension) < 0){
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Apenas imagens são permitidas.',
            });
            return;
          }

          let obj = {
            file: event.target?.result,
            extension: fileExtension,
            path: originalEvent.target?.value,
          };
          this.filesVet.push(obj);
          this.onFilesUploaded.emit(this.filesVet);
          if (this.maxFileQuantity <= this.filesVet.length) {
            this.disableUpload = true;
          } else {
            this.disableUpload = false;
          }
        };
      });
    }
  }
}
