
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Plan } from '../models/plan';
import { environment } from 'src/environments/environment';
import { Subscription } from '../models/subscription';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  private apiUrl = `${environment.paymentApiUrl}/plans`;
  private subscriptionsUrl = `${environment.paymentApiUrl}/subscriptions`

  constructor(private http: HttpClient) { }

  public currentUserPlan: EventEmitter<Subscription> = new EventEmitter<Subscription>();
  public hasPlan: EventEmitter<boolean> = new EventEmitter<boolean>();

  getPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(this.apiUrl);
  }

  getPlanByName(planName: string): Observable<Plan> {
    const url = this.apiUrl + '/' + planName;
    return this.http.get<Plan>(url);
  }

  getCurrentPlan(): Observable<Subscription> {
    return this.http.get<Subscription>(this.subscriptionsUrl);
  }

  cancelPlan(planId: number): Observable<any> {
    const url = this.apiUrl + '/' + '/cancel';
    return this.http.post(url, {});
  }

  reactivatePlan(planId: number): Observable<any> {
    const url = this.apiUrl + '/' + 'reactivatePlan';
    return this.http.post(url, {});
  }
  //
  upgradePlan(planId: any): Observable<any> {
    return this.http.put<any>(`${this.subscriptionsUrl}/upgrade`, planId);
  }
  downgradePlan(planId: any): Observable<any> {
    return this.http.put<any>(`${this.subscriptionsUrl}/downgrade`, planId);
  }
}
