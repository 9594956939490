<div class="host-page w-100 d-flex flex-col">
    <div class="filter-wrapper">
        <input 
                class="input search-bar" 
                id="search-bar" placeholder="Digite o nome ou título do anfitrião..."
                type="text" 
                [(ngModel)]="searchText"
                (input)="searchUsers()" 
                />
      <!-- <button class="filter-btn" (click)="isShowFilterModal = !isShowFilterModal">
            <svg-icon name="filter-uncircles-filled"></svg-icon>
        </button> -->
    </div>
    <div class="filter-modal" [hidden]="!isShowFilterModal">
        <div class="row">
            <label for="week-days">Dias da semana:</label>
            <input type="text">
        </div>
        <div class="row">
            <label for="event-types">Tipos de Evento:</label>
            <input type="text">
        </div>
        <div class="row">
            <label for="hosts">Anfitriões:</label>
            <input type="text">
        </div>
        <div class="row">
            <label for="event-duration">Duração:</label>
            <input id="event-duration" type="text" class="input" disabled="true" />

        </div>
        <div class="row">
            <label for="event-date">Data:</label>
            <p-calendar id="event-start-date" class="col-md-6" placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy"
                [showIcon]="true" inputId="icon"></p-calendar>
            <p-calendar id="event-and-date" class="col-md-6" placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy"
                [showIcon]="true" inputId="icon"></p-calendar>
        </div>
        <div class="row">
            <button class="primary-button">
                <!--  <svg-icon name="search-unfilled"></svg-icon>--> Pesquisar
            </button>
        </div>
    </div>

    <div class="host-list">
            <div class="host" *ngFor="let element of filteredHosts" (click)="openHostPage(element)">
                <h3 class="title">{{element.specialty}}</h3>
                <div class="info">
                    <div class="profile-picture">
                        <img *ngIf="element.profileImg" class="picture com" [src]="element.profileImg" alt="">
                        <img *ngIf="!element.profileImg" class="picture sem" src="/assets/images/blank-profile.svg" alt="">
                    </div>
                    <div class="text">
                        <h3 class="name">{{ element?.name }} {{ element?.surname }}</h3>
                        <span class="tag">@{{ element?.nickname }}</span>
                        {{ element?.description }}
                    </div>
                </div>
            </div>
    </div>
</div>
