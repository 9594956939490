import { EventsComponent } from './../events.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Event } from 'src/app/models/event';
import { User } from 'src/app/models/user';
import { EventService } from 'src/app/services/event.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Output()
  subscribeToEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() event: Event = new Event();
  @Input() showfooter = true;
  @Input() isConfirmed = false;
  @Input() isInviteVersion: boolean = false;
  @Input() currentTabId: string = '';
  host: User = new User();
  hostName: any;
  hostProfileImage: any;

  constructor(
    private userDataService: UserDataService,
    private eventService: EventService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userDataService
      .getUserById(this.event.user_id!)
      .subscribe((result: any) => {
        this.host = result.data;
        // console.log(this.host);
      });
  }

  openEventDetails() {
    this.router.navigateByUrl('/event/' + this.event.id);
  }

  confirmPresence() {
    this.eventService
      .confirmPresence(this.event.id)
      .subscribe((result: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Presença confirmada!',
        });
        this.isConfirmed = true;
      });
  }
}
