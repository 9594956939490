import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'agoora-frontend';
  hideNavbar: boolean = true;
  cartProductsQuantity: number = 0;
  cart: any;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.loginEvent.subscribe(() => this.hideNavbar = !this.authService.isLogged());
    this.hideNavbar = !this.authService.isLogged();
    if (this.getCartProductQuantity > 0) {
      this.cartProductsQuantity = this.getCartProductQuantity;
    }
  }

  addToCart(productId: string) {
    if (this.cart[productId]) {

      this.cart[productId]++;
    } else {

      this.cart[productId] = 1;
    }
  }


  get getCartProductQuantity() {
    let quantity = 0;
    if (localStorage) {

      for (let i = 0; i < localStorage.length; i++) {
        const key: any = localStorage.key(i);
        const storedValue: any = localStorage.getItem(key);

        if (storedValue) {
          try {
          const storedObject = JSON.parse(storedValue);
          if (storedObject && typeof storedObject.quantity === 'number') {
            quantity += storedObject.quantity;
          }
        }
        catch {

        }
      }
      }
    }
    return quantity;
  }

  get isShowingCartBadge() {
    if (this.getCartProductQuantity > 0 &&
      window.location.href.indexOf('login') <= -1 &&
      window.location.href.indexOf('cart') <= -1 &&
      window.location.href.indexOf('passwd') <= -1) {
      return true;
    }
    return false;
  }

  goToCart() {
    this.router.navigateByUrl('/app/cart');
  }
}
