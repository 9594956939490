import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pass-validation',
  templateUrl: './pass-validation.component.html',
  styleUrls: ['./pass-validation.component.scss'],
})
export class PassValidationComponent implements OnInit {
  @Output()
  modalClosed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onPasswdSet: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Output()
  onPasswordConfirmed: EventEmitter<any> = new EventEmitter<any>();

  @Input() isModalOpen: boolean = true;
  @Input() isEditing: boolean = false;

  @Input() choosenPassword: string = '';
  repeatPassword: string = '';

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  validatePasswd() {
    if (this.isEditing) {
      ///CONFIRMATION CASE
      const email = this.authService.getCredentials();
      let data = {
        email: email.toLowerCase().trim(),
        password: this.repeatPassword,
      };
      const self = this;

      this.authService.confirmCredentials(data).subscribe(
        (result: any) => {
          this.onPasswdSet.emit(true);
          this.onPasswordConfirmed.emit(this.repeatPassword);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.error.message ?? error.error,
          });
          this.onPasswdSet.next(false);
        }
      );
    } else {
      ///REGISTRATION CASE
      if (this.repeatPassword === this.choosenPassword) {
        this.onPasswdSet.emit(true);
      } else {
        this.onPasswdSet.emit(false);
      }
    }
  }
}
