export class Media {
  [key: string]: string | number | Date;
  id: number = 0;
  userId: number = 0;
  title: string = "";
  media_type: string = "";
  subtype: string = "";
  thumbnail: any = "";
  file: any = "";
  file_name: string = "";
  size: number = 0;
  fileName: string ="";
  mime_type: string = "";
  thumbnail_key: string = "";
  file_key: string="";
  folder_id=""
  created_at: Date = new Date();
  updated_at: Date = new Date();
}
