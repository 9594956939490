import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  placeholder: any[] = [
    {
      URL: '',
      thumbnailImageSrc: '',
      alt: '',
      title: '',
    },
  ];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  @Output()
  modalClosed: EventEmitter<string> = new EventEmitter<string>();
  
  @Input() isModalOpen: boolean = true;

  @Input() isReadOnly: boolean = false;

  @Input() maxWidth: string = '70rem';


  selectedProductType: any;

  activeIndex: number = 0;
  productPicturesVet: any[] = [];
  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.isModalOpen = false;
    this.modalClosed.next('');
  }

}
