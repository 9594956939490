import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalExperiencesService {

  constructor(private http:HttpClient) { }


  public getAllExperiences() {
    return this.http.get(environment.apiUrl + 'experiences');
  }

  public getCurrentUserExperiences() {
    return this.http.get(environment.apiUrl + 'experiences/user');
  }
}
