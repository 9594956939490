<div class="media-admin-wrapper">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@300;400;500&display=swap" rel="stylesheet">
  <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous"> -->

   <!-- start of modal logic -->
  <p-dialog
  [(visible)]="isModalVisible"
  [style]="{ width: '60vw' }"
  [modal]="true"
  styleClass="p-fluid"
  [draggable]="false"
  [closable]="!sendingFileLoading">

  <div *ngIf="sendingFileLoading">
    <div class="notification">
      <p-progressSpinner [style]="{ width: '50px', height: '50px' }"  styleClass="w-4rem h-4rem" strokeWidth="8"></p-progressSpinner>
      <p>Por favor não feche a janela e aguarde até que o arquivo seja completamente enviado</p>
    </div>
  </div>

  <div *ngIf="!sendingFileLoading">
    <span style="font-size: 1.5rem; font-weight: bold; text-align: center; width: 100%;display: block;">{{action == 'edit'? 'Alterar Midia': (action == 'remove'? '': 'Adicionar Midia')}}</span>
    <!-- Remove case modal -->
      <div *ngIf=" action == 'remove'">

        <div style="width: 100%; text-align: center;">
          <span style="color: #b70101; font-size: 2rem; font-weight: bold">Tem certeza que deseja excluir esta mídia?</span>
        </div>
        <p style="text-align: center; font-weight: bold; font-size: 1.5rem;">
          Título: {{selectedMedia.title}}
        </p>
        <div class="buttons-group">
          <button pButton (click)="proceedAction({action: action})"  [loading]="loadingRemoveAction" label="Excluir" class="p-button-danger modal-buttons"></button>
        </div>
      </div>

      <!-- Add case modal -->
      <div *ngIf="action == 'add'">
        <media-admin-form (proceedEventCalled)="proceedAction($event)" [action]="action"></media-admin-form>
      </div>

      <!-- Edit case modal -->
      <div *ngIf="action == 'edit'">
        <media-admin-form [selectedMedia]="selectedMedia" (proceedEventCalled)="proceedAction($event)" [action]="action" ></media-admin-form>
      </div>
    </div>
  </p-dialog>

  <p-dialog
  [(visible)]="downloadingFile"
  [style]="{ width: '60vw' }"
  [modal]="true"
  styleClass="p-fluid"
  [draggable]="false"
  [closable]="false">
  <div>
    <div class="notification">
      <p-progressSpinner [style]="{ width: '50px', height: '50px' }"  styleClass="w-4rem h-4rem" strokeWidth="8"></p-progressSpinner>
      <p>Por favor não feche a janela e aguarde até que o arquivo seja completamente preparado e baixado</p>
    </div>
  </div>
  </p-dialog>


  <p-dialog
  [(visible)]="showAddFolderModal"
  [style]="{ width: '60vw' }"
  [modal]="true"
  styleClass="p-fluid"
  [draggable]="false">
  <div class="row">
    <div class="form-item col-md-12">
      <label for="folder-name">Nome da Pasta:</label>
      <input class="input" id="folder-name" type="text"
        placeholder="Digite o Nome da pasta"
        [(ngModel)]="newFolder.name"
        required >
    </div>
      <div class="center">
        <button pButton (click)="createFolder()" label="Adicionar Pasta" class="p-button-success modal-buttons"></button>
      </div>
    </div>
  </p-dialog>

  <p-dialog
  [(visible)]="showEditFolderModal"
  [style]="{ width: '60vw' }"
  [modal]="true"
  styleClass="p-fluid"
  [draggable]="false">
  <div class="row">
    <div class="form-item col-md-12">
      <label for="folder-name">Nome da Pasta:</label>
      <input class="input" id="folder-name" type="text"
        placeholder="Digite o Nome da pasta"
        [(ngModel)]="editFolder.name"
        required >
    </div>
      <div class="center">
        <button pButton (click)="updateFolder()" label="Editar Pasta" class="p-button-success modal-buttons"></button>
      </div>
    </div>
  </p-dialog>


  <p-toast key="notification" position="top-right"></p-toast>


      <div *ngIf="isLoadingMedia" >
          <div class="center">
            <p-progressSpinner [style]="{ width: '50px', height: '50px' }"  styleClass="w-4rem h-4rem"></p-progressSpinner>
          </div>
      </div>


        <!-- <div *ngIf="!medias.length"  class="center-notification">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-inbox" viewBox="0 0 16 16">
              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
            </svg>
            <p>Esta pasta está vazia</p>
            <div class="center">
              <button type="button" class="primary-button" (click)="showModal(0, 'add')" >Adicionar Mídia</button>
        </div >
      </div> -->

      <div class="table" *ngIf="!isLoadingMedia">
        <div class="folder-hierarchy" >

          <a (click)="navigateToFolder()" class="folder-navigation" *ngIf="currentFolder.motherFolderName">
            {{currentFolder.motherFolderName}}
          </a>

          <a (click)="navigateToFolder(currentFolder.id)" class="folder-navigation">
            {{currentFolder.motherFolderName? '&nbsp;>&nbsp;' + currentFolder.name: currentFolder.name}}
          </a>

          <div class="dropdown-arrow" (click)="menu.toggle($event)"></div>
          <div class="folders-menu">
            <p-tieredMenu #menu [model]="menuItems" [popup]="true" appendTo="body" ></p-tieredMenu>
          </div>
        </div>

        <div class="table-content">
          <div class="content-row-head">
            <div class="col-md-6">
              Nome
            </div>

            <div class="col-md-1">
              Tipo
            </div>

            <div class="col-md-2">
              Última modificação
            </div>

            <div class="col-md-1">
              Tamanho
            </div>

            <div class="col-md-2">

            </div>

          </div>
          <!-- começo das folders -->
          <div *ngFor="let folder of folders" class="content-row">
            <div class="col-md-6" style="display: flex;flex-direction: row; align-items: center;">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                  <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                </svg>
              </div>

              <a (click)="navigateToFolder(folder.id)"  class="folder-navigation file-name">
                <div>{{folder.name}}</div>
              </a>

            </div>

            <div class="col-md-1">
              Pasta
            </div>

              <div class="modified-date col-md-2">
                {{folder.updated_at | date: "dd/MM/yyyy"}}, {{folder.updated_at | date: "HH:mm"}}
              </div>

              <div class="file-size col-md-1">
                —
              </div>

              <div class="options col-md-2">
                <button type="button" class="option-icon" (click)="showModalAddOrEditFolder(folder.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16" >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </button>

                <button type="button" class="option-icon" (click)="removeFolder(folder.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                    </svg>
                </button>

              </div>

          </div>
          <!-- final das folders -->

          <!-- inicio de arquivos -->
          <div *ngFor="let media of medias" class="content-row">
            <div class="col-md-6" style="display: flex;flex-direction: row; align-items: center;">
              <div class="icon">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                  <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                </svg> -->
                <!-- imagem -->

                <!-- Image icon -->
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image-fill" viewBox="0 0 16 16"
                *ngIf="media.media_type == 'Foto 360' || media.media_type == 'Foto 360/3d'">
                  <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
                </svg>

                <!-- Video icon -->
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-play-fill" viewBox="0 0 16 16"
                *ngIf="media.media_type == 'Vídeo 2d' || media.media_type == 'Vídeo 360/3d' || media.media_type == 'Vídeo 360 Mono'">
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z"/>
                </svg>

                <!-- Audio icon -->
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-music-fill" viewBox="0 0 16 16"
                *ngIf="media.media_type == 'Áudio'">
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 6.64v1.75l-2 .5v3.61c0 .495-.301.883-.662 1.123C7.974 13.866 7.499 14 7 14c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 11.134 6.501 11 7 11c.356 0 .7.068 1 .196V6.89a1 1 0 0 1 .757-.97l1-.25A1 1 0 0 1 11 6.64z"/>
                </svg>
              </div>

              <!-- <a (click)="downloadFile(media.file)"  class="folder-navigation file-name "> -->
                <div>{{media.title}}</div>
              <!-- </a> -->
            </div>

            <div class="col-md-1">
              {{media.media_type}}
            </div>

            <div class="modified-date col-md-2">
              {{media.updated_at | date: "dd/MM/yyyy"}}, {{media.updated_at | date: "HH:mm"}}
            </div>

            <div class="file-size col-md-1">
              {{transform(media.size)}}
            </div>

            <div class="options col-md-2">

              <button type="button" class="option-icon" (click)="showModal(media.id, 'edit')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16" >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </button>

            <button type="button" class="option-icon" (click)="showModal(media.id, 'remove')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                </svg>
            </button>

            <!-- <a [href]="media.file" download target="_blank">
            <button type="button" class="option-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
              </button>
            </a> -->

            <button type="button" class="option-icon" (click)="downloadFile(media.file)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
              </button>

            </div>

        </div>
          <!-- final de arquivos -->


        </div>


        <div *ngIf="!medias.length && !folders.length"  class="center-notification">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-inbox" viewBox="0 0 16 16">
            <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
          </svg>
          <p>Essa pasta está vazia</p>
        </div >

      <div class="center-button">
        <button type="button" class="primary-button" (click)="showModal(0, 'add')" >Adicionar Mídia</button>
      </div >




</div>
