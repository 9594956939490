<div class="event-wrapper" *ngIf="user">
  <i class="pi pi-arrow-left back-icon" (click)="goToEvents()"></i>
  <img [src]="event.url_thumbnail" class="background-image" alt="" />
  <div class="light-gray-background">
    <div class="event-title">{{ event.title }}</div>
    <div class="event-details">
      <div class="upper-side">
        <div class="time-infos">
          <div class="event-date">
            {{ event.initial_date | date : "dd MMM yyyy" }}
          </div>
          <div class="event-time">
            {{ event.initial_date | date : "HH:mm" }}h
          </div>
          <div class="event-duration">
            Evento ({{ event.type }}) de {{ event.duration }}
          </div>
        </div>
        <div class="event-host">
          Organizador:
          <p-avatar [image]="user?.profileImg" size="xlarge" shape="circle"></p-avatar>
        </div>
      </div>
      <div class="division-line"></div>
      <div class="event-description">{{ event.description }}</div>
      <p>
        {{ this.event.is_public ? 'Evento público' : 'Evento particular' }}
      </p>

      <div class="align-self-center" *ngIf="user && event.user.id === user.id">
        <button class="primary-button" (click)="openModal1()">Editar Evento</button>
      </div>
    </div>
    <button class="tertiary" (click)="onActionButtonClick()">
      {{ isUserOrganizer ? 'Cancelar Evento' : 'Confirmar Presença' }}
    </button>

  </div>
  <div class="dark-gray-background">
    <div class="event-banner">
      <img [src]="event.url_thumbnail" class="background-img" alt="" />
    </div>
    <!--
    <div class="avatar-group">
      <p-avatarGroup styleClass="mb-3">
        <p-avatar
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
          size="large"
          shape="circle"
        ></p-avatar>
        <p-avatar
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
          size="large"
          shape="circle"
        ></p-avatar>
        <p-avatar
        image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
        size="large"
        shape="circle"
      ></p-avatar>
      <p-avatar
      image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
      size="large"
      shape="circle"
    ></p-avatar>
    <p-avatar
    image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
    size="large"
    shape="circle"
  ></p-avatar>
  <p-avatar
  image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
  size="large"
  shape="circle"
  ></p-avatar>
      </p-avatarGroup>
    </div>-->
  </div>
</div>
<div class="event-wrapper d-flex justify-content-center align-items-center" *ngIf="!user || isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>

<app-edit-event-modal *ngIf="isModalOpen" [event]="event" [isModalOpen1]="isModalOpen"
  (modalClosed1)="isModalOpen = false"></app-edit-event-modal>