export class SetProductDto {
  produto_name: any;
  thumbnail: any = '';
  description: string = '';
  value: any = 0;
  id: any;
  type: any = 'public';
  /////////////////////////////
  url_thumbnail?: any = '';
  category?: any = '';
  quantity?: any = 1;
  itemType?: any;
}

export class GetProductDTO {
  id: number = -1;
  created_at: string = '';
  updated_at: string = '';
  nome: string = '';
  descricao: string = '';
  valor: string = '';
  url_thumbnail: string = '';
  type: string = '';

  static convertDTO(input: GetProductDTO): SetProductDto {
    let output: SetProductDto = {
      produto_name: input.nome,
      thumbnail: input.url_thumbnail,
      description: input.descricao,
      value: input.valor,
      id: input.id,
      type: input.type,
    };
    return output;
  }
}
