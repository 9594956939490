import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Address, Gender, User } from 'src/app/models/user';
import { GeographyService } from 'src/app/services/geography.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class UserProfileModalComponent implements OnInit {
  isLoading: boolean = false;
  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private geographyService: GeographyService,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit(): void {
    if (this.isEditing) {
      this.fetchCities();
    }
  
  }

  @Output()
  modalClosed2: EventEmitter<string> = new EventEmitter<string>();

  @Input() isModalOpen2: boolean = true;

  @Input() isReadOnly: boolean = false;

  @Input() isEditing: boolean = false;

  @Input()
  user: User = {
    id: null,
    name: '',
    nickname: '',
    gender: null,
    email: '',
    password: '',
    registrationDate: new Date(),
    cpf: '',
    birthday: undefined,
    description: '',
    phoneNumber: '',
    active: true,
    profileBackground: '',
    profileImg: '',
    address: {
      cep: '',
      country: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      complement: '',
      number: '',
    },
  };

  isValidationModalOpen: boolean = false;
  selectedGender: any;
  selectedPlan: any;
  birthDate: any;
  isHost: boolean = false;
  selectedCountry: any = 'BR';
  selectedState: any;
  selectedCity: any;
  countries: any[] = [{ value: 'BR', title: 'Brasil' }];
  cities: any[] = [];
  states: any[] = [
    { value: 'AC', title: 'Acre' },
    { value: 'AL', title: 'Alagoas' },
    { value: 'AP', title: 'Amapá' },
    { value: 'AM', title: 'Amazonas' },
    { value: 'BA', title: 'Bahia' },
    { value: 'CE', title: 'Ceará' },
    { value: 'DF', title: 'Distrito Federal' },
    { value: 'ES', title: 'Espírito Santo' },
    { value: 'GO', title: 'Goiás' },
    { value: 'MA', title: 'Maranhão' },
    { value: 'MT', title: 'Mato Grosso' },
    { value: 'MS', title: 'Mato Grosso do Sul' },
    { value: 'MG', title: 'Minas Gerais' },
    { value: 'PA', title: 'Pará' },
    { value: 'PB', title: 'Paraíba' },
    { value: 'PR', title: 'Paraná' },
    { value: 'PE', title: 'Pernambuco' },
    { value: 'PI', title: 'Piauí' },
    { value: 'RJ', title: 'Rio de Janeiro' },
    { value: 'RN', title: 'Rio Grande do Norte' },
    { value: 'RS', title: 'Rio Grande do Sul' },
    { value: 'RO', title: 'Rondônia' },
    { value: 'RR', title: 'Roraima' },
    { value: 'SC', title: 'Santa Catarina' },
    { value: 'SP', title: 'São Paulo' },
    { value: 'SE', title: 'Sergipe' },
    { value: 'TO', title: 'Tocantins' },
  ];

  genders: any = [
    {
      title: 'Outro',
      value: 'other',
    },
    {
      title: 'Masculino',
      value: 'male',
    },
    {
      title: 'Feminino',
      value: 'female',
    },
  ];

  plans: any = [
    {
      title: 'Basic',
      value: 'basic',
    },
    {
      title: 'Standard',
      value: 'standard',
    },
    {
      title: 'Premium',
      value: 'premium',
    },
  ];

  errorDic: any = {
    'user.name': false,
    'user.surname': false,
    'user.nickname': false,
    'user.specialty': false,
    'user.birthday': false,
    'user.cpf': false,
    'user.phoneNumber': false,
    'user.email': false,
    'user.gender': false,
    'user.address.cep': false,
    'user.address.state': false,
    'user.address.city': false,
    'user.address.neighborhood': false,
    'user.address.country': false,
    'user.address.street': false,
    'user.address.number': false,
    'user.address.complement': false,
    'user.description': false,
    'user.password': false,
  };

  didCepSearch: any = false;
  adress: any;

  invalidCpf = false;
  duplicateCpf = false;

  cepNotFound = false;
  invalidCepFormat = false;

  cepResponsibleNotFound = false;

  invalidEmail = false;
  duplicateEmail = false;
  invalidBirthDate = false;

  equalCpf = false;

  invalidAddressLine = false;
  invalidDistrict = false;
  invalidCountry = false;

  monthError = false;
  dayError = false;

  blockField = false;
  inputError = false;

  saveUser() {
    if (!this.hasErrors()){
      this.isValidationModalOpen = true;
    }
  }

  getCep(event: any) {
    this.didCepSearch = false;
    this.cepNotFound = false;
    this.invalidCepFormat = false;

    if (!this.user.address.cep) {
      this.user.address.street = '';
      this.user.address.complement = '';
      this.user.address.neighborhood = '';
      this.user.address.city = '';
      this.user.address.state = '';
    } else {
      this.geographyService
        .getFullAddressByCep(this.user.address.cep)
        .subscribe(
          (e: any) => {
            this.user.address.street = e.logradouro;
            this.user.address.complement = e.complemento;
            this.user.address.neighborhood = e.bairro;
            this.user.address.city = e.localidade;
            this.user.address.state = e.uf;
            this.user.address.country = 'Brasil';

            this.cities = [e.localidade];

            if (!e.bairro) {
              this.cepNotFound = true;
            }
            this.didCepSearch = true;

            if (this.user.address.street) {
              this.blockField = true;
            }
          },
          (error: any) => {
            this.cepNotFound = true;

            if (error.status === 0) {
              this.invalidCepFormat = true;
            }
          }
        );
    }

    if (this.user.address.street) {
      this.inputError = false;
    }
    if (!this.user.address.street) {
      this.inputError = true;
      this.blockField = false;
    }
  }

  getBannerPic(event: any) {
    this.user.profileBackground = event[0];
  }

  getProfilePic(event: any) {
    this.user.profileImg = event[0];
  }

  fetchCities() {
    this.geographyService
      .getCitiesByUF(this.user.address)
      .subscribe((cities: any) => {
        this.cities = cities.map((city: any) => city.nome);
      });
  }

  saveData(confirmed: any) {
    if (confirmed) {
      if (this.isEditing) {
        const userpayload = {
          ...this.user,
          password:confirmed
        }
        this.userService.updateUser(userpayload).subscribe(
          (result: any) => {
            this.confirmationService.confirm({
              message: 'Usuário editado com sucesso!',
              header: 'Sucesso!',
              icon: 'pi pi-check',
              accept: () => {
                this.modalClosed2.emit();
              },
            });
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: error.error.message ?? error.error,
            });
            this.isLoading = false;
          }
        );
      } else {
        this.userService.addUser(this.user).subscribe(
          (result: any) => {
            this.confirmationService.confirm({
              message: 'Usuário salvo com sucesso!',
              header: 'Sucesso!',
              icon: 'pi pi-check',
              accept: () => {
                this.modalClosed2.emit();
              },
            });
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: error.error.message ?? error.error,
            });
            this.isLoading = false;
          }
        );
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Houve um erro de autenticação. Verifique as senhas e tente novamente.',
      });
    }
  }

  validateCPF() {
    if (!this.user.cpf) {
      return;
    }
    this.user.cpf = this.mascaraCpf(this.user.cpf);
    this.invalidCpf = false;
  }

  mascaraCpf(valor: string) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  hasErrors() {
    const fieldDic = [
      { field: 'Nome', value: this.user.name, errorKey:'user.name' },
      { field: 'Sobrenome', value: this.user.surname, errorKey:'user.surname' },
      { field: 'Apelido', value: this.user.nickname, errorKey:'user.nickname' },
      { field: 'Especialidade', value: this.user.specialty, errorKey:'user.specialty' },
      { field: 'Data de nascimento', value: this.user.birthday, errorKey:'user.birthday' },
      { field: 'CPF', value: this.user.cpf, errorKey:'user.cpf' },
      { field: 'Telefone', value: this.user.phoneNumber, errorKey:'user.phoneNumber' },
      { field: 'Email', value: this.user.email, errorKey:'user.email' },
      { field: 'Genero', value: this.user.gender, errorKey:'user.gender' },
      { field: 'CEP', value: this.user.address.cep, errorKey:'user.address.cep' },
      { field: 'Estado', value: this.user.address.state, errorKey:'user.address.state' },
      { field: 'Cidade', value: this.user.address.city, errorKey:'user.address.city' },
      { field: 'Bairro', value: this.user.address.neighborhood, errorKey:'user.address.neighborhood' },
      { field: 'País', value: this.user.address.country, errorKey:'user.address.country' },
      { field: 'Rua', value: this.user.address.street, errorKey:'user.address.street' },
      { field: 'Número', value: this.user.address.number, errorKey:'user.address.number' },
      { field: 'Complemento', value: this.user.address.complement, errorKey:'user.address.complement' },
      { field: 'Descrição', value: this.user.description, errorKey:'user.description' },
      
    ];

    let hasError = false;

    fieldDic.forEach((field) => {
      this.errorDic[field.errorKey] = false;
      if (field.field == 'Escolha uma senha:' && this.user.id != null) {  
      }
     else if (!field.value) {     
        this.errorDic[field.errorKey] = true;   
        if(!hasError){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: `${field.field} inválido!`,
          });
        }

        hasError = true;

        
      }
    });

    return hasError;
  }
}
