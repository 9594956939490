import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {  
  isModalOpen2: boolean = false;
  viewPasswd: boolean = false;
  inputData = {
    email: '',
    password: '',
  };

  errorMessage = '';
  ano = new Date();


  displayBasic2: boolean = false;

  activeIndex: number = 0;

  imageClick(index: number) {
    this.activeIndex = index;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.authService.isLogged()) {
      this.router.navigateByUrl('/home');
    }

    this.authService.loginError.subscribe((result: string) => {
      this.errorMessage = result;
    });

  }

  login(): void {
    this.errorMessage = '';
    if (this.inputData.email.length < 1 || this.inputData.password.length < 1) {
      this.errorMessage = 'Usuário ou senha inválidos';
      return;
    }

    this.authService.Login(this.inputData.email, this.inputData.password);
  }

}
