import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Event } from '../models/event';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  public getFilteredEvents(filter: any) {
    return this.http.post(environment.apiUrl + 'event/filter', filter);
  }

  public getEvent(id: string) {
    return this.http.get(environment.apiUrl + 'event/' + id);
  }

  public delEvent(id: string) {
    return this.http.delete(environment.apiUrl + 'event/' + id);
  }

  public getAllEvents() {
    return this.http.get(
      environment.apiUrl + 'event/?take=1000000&skip=0&type=all'
    );
  }

  public getAllInvites() {
    return this.http.get(environment.apiUrl + 'invite');
  }

  public addEvent(event: Event) {
    event.initial_date?.setHours(event.initial_selected_hour!.getHours());
    event.initial_date?.setMinutes(event.initial_selected_hour!.getMinutes());
    event.user_id = this.authService.getUserId();
    event.url_event = 'www.google.com';

    let eventReal: any = JSON.parse(JSON.stringify(event));

    delete eventReal.url_thumbnail;
    delete eventReal.timeTillDate;
    delete eventReal.created_at;
    delete eventReal.creatorHost;
    delete eventReal.user_id;
    delete eventReal.id;

    eventReal.initial_date = new Date(eventReal.initial_date);
    eventReal.initial_date.setHours(event.initial_selected_hour!.getHours());
    eventReal.initial_date.setMinutes(
      event.initial_selected_hour!.getMinutes()
    );

    delete eventReal.initial_selected_hour;

    eventReal.final_date = JSON.parse(JSON.stringify(event.initial_date));
    eventReal.final_date = new Date(eventReal.final_date);
    eventReal.final_date = eventReal.final_date.setMinutes(
      eventReal.final_date.getMinutes() + event.duration
    );
    eventReal.final_date = new Date(eventReal.final_date);

    eventReal.final_date = eventReal.final_date!.toISOString();
    eventReal.initial_date = eventReal.initial_date!.toISOString();

    var formData = new FormData();

    const file = eventReal.thumbnail_url;
    const fileContent = eventReal.thumbnail;

    delete eventReal.thumbnail;

    Object.keys(eventReal).forEach((key) => {
      formData.append(key, eventReal[key]);
    });

    if (fileContent) {
      formData.append('thumbnail', this.b64toBlob(fileContent.file), file);
    }


    if (eventReal.guests_users_id) {
      formData.set(
        'guests_users_id',
        JSON.stringify(eventReal.guests_users_id)
      );
    }

    return this.http.post(environment.apiUrl + 'event/', formData);
  }

  public updateEvent(event: Event) {

    let eventReal: any | Event = JSON.parse(JSON.stringify(event));

    eventReal.initial_date = new Date(eventReal.initial_date);
    eventReal.initial_date.setHours(event.initial_selected_hour!.getHours());
    eventReal.initial_date.setMinutes(
      event.initial_selected_hour!.getMinutes()
    );

    delete eventReal.initial_selected_hour;

    eventReal.final_date = JSON.parse(JSON.stringify(eventReal.initial_date));
    eventReal.final_date = new Date(eventReal.final_date);
    eventReal.final_date = eventReal.final_date.setMinutes(
      eventReal.final_date.getMinutes() + parseInt(event.duration)
    );
    eventReal.final_date = new Date(eventReal.final_date);
    eventReal.final_date = eventReal.final_date!.toISOString();
    eventReal.initial_date = eventReal.initial_date!.toISOString();

    const formData = new FormData();

    Object.keys(eventReal).forEach((key) => {
      if (key === 'user' || key === 'user_id') {
        return;
      }
      formData.append(key, eventReal[key]);
    });


    const file = eventReal.thumbnail_url;
    const fileContent = eventReal.thumbnail;



    if (fileContent) {
      formData.append('thumbnail', this.b64toBlob(fileContent.file), file);
    }

    if (eventReal.guests_email) {
      formData.append('guests_users_id', JSON.stringify(eventReal.guests_email));
    }

    delete eventReal.timeTillDate;
    delete eventReal.created_at;
    delete eventReal.creatorHost;
    delete eventReal.id;
    delete eventReal.updated_at;
    //delete eventReal.guests_email;
    delete eventReal.initial_selected_hour;
    delete eventReal.url_thumbnail;

    return this.http.put(environment.apiUrl + 'event/' + event.id, formData);
  }


  public getHostPublicEvents(host_id: any) {
    return this.http.get(
      environment.apiUrl +
        'event?user_id=' +
        host_id +
        '&is_public=true&take=100&skip=0&type=specific'
    );
  }

  private b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data.split('base64,')[1]);
    const byteArrays = [];

    console.dir('byteCharacters', byteCharacters);

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    console.dir(`b64Data.split('base64,')[1]`, b64Data.split('base64,')[0]);

    const blob = new Blob(byteArrays, { type: b64Data.split('base64,')[0] });
    return blob;
  }
  public getMyEvents(host_id: any) {
    return this.http.get(
      environment.apiUrl +
        'event?user_id=' +
        host_id +
        '&take=100&skip=0&type=specific'
    );
  }

  public confirmPresence(eventId: any) {
    return this.http.post(environment.apiUrl + 'invite/confirmation', {
      confirmation: 'true',
      event_id: eventId,
    });
  }
}
