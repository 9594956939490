<p-confirmDialog #cd [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <h3>Adicionar evento</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Substituir" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
<div class="events-wrapper">
  <div class="filter-wrapper">
    <input class="input search-bar" placeholder="Digite o nome ou tema do evento..." [(ngModel)]="searchField"
      (ngModelChange)="onSearchFieldChange()" type="text" />
    <button class="filter-btn" (click)="isShowFilterModal = !isShowFilterModal">
      <svg-icon name="filter-uncircles-filled"></svg-icon>
    </button>
    <div class="filter-modal" [hidden]="!isShowFilterModal">
      <div class="row">
        <label for="week-days">Dias da semana:</label>
        <p-multiSelect id="week-days" [options]="weekDays" [(ngModel)]="filter.days_week"
          placeholder="Selecione os dias..." [optionValue]="'value'" [optionLabel]="'title'">
        </p-multiSelect>
      </div>
      <div class="row">
        <label for="event-types">Tipos de Evento:</label>
        <p-dropdown id="event-types" [options]="eventTypes" [(ngModel)]="filter.event_type"
          placeholder="Selecione o tipo..." [optionValue]="'value'" [optionLabel]="'title'"></p-dropdown>
      </div>
      <div class="row">
        <label for="hosts">Anfitriões:</label>
        <p-multiSelect id="hosts" [options]="allHostsList" placeholder="Selecione os anfitriões..."
          [(ngModel)]="filter.hosts" [optionValue]="'id'" [optionLabel]="'name'">
        </p-multiSelect>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label for="event-duration">Duração:</label>
          <input id="event-duration" type="text" value="{{ filter.duration }} minutos" class="input" disabled="true" />
          <p-slider [(ngModel)]="filter.duration" [step]="30" [min]="30" [max]="480" class=""></p-slider>
        </div>
      </div>
      <div class="row">
        <label for="event-date">Data:</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          id="event-start-date"
          class="col-md-6"
          [(ngModel)]="filter.start"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          inputId="icon"
        ></p-calendar>
        <p-calendar
          dateFormat="dd/mm/yy"
          id="event-and-date"
          class="col-md-6" 
          [(ngModel)]="filter.end"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          inputId="icon"
        ></p-calendar>
      </div>
      <div class="row">
        <button (click)="searchEvents()" class="primary-button">
          <svg-icon name="search-unfilled"></svg-icon> Pesquisar
        </button>
      </div>
    </div>
  </div>
  <p-tabView class="subpanel" [(activeIndex)]="tabIndex">
    <p-tabPanel header="Todos os Eventos">
      <div class="event-list-wrapper" *ngIf="!loadingAllEvents">
        <app-event-card (subscribeToEvent)="subscribeToEvent($event)" class="event" *ngFor="let event of allEventList"
          [event]="event"></app-event-card>

      </div>
      <div class="w-100 d-flex justify-content-center align-items-center" *ngIf="loadingAllEvents">
        <p-progressSpinner ></p-progressSpinner>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Meus Eventos">
      <div class="card-add-event">
        <button class="add-event" (click)="openModal1()" class="primary-button">
          <div class="square-icon">
            <p class="event-p">
              Criar Evento
            </p>
          </div>
        </button>
      </div>
      <div class="event-list-wrapper" *ngIf="!loadingMyEvents">
        <app-event-card (subscribeToEvent)="subscribeToEvent($event)" class="event" *ngFor="let event of myEventList"
          [event]="event" [currentTabId]="'Meus Eventos'"></app-event-card>

      </div>        
      <div class="w-100 d-flex justify-content-center align-items-center" *ngIf="loadingMyEvents">
          <p-progressSpinner ></p-progressSpinner>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Convites">
      <div class="event-list-wrapper">
        <app-event-card (subscribeToEvent)="subscribeToEvent($event)" class="event" [isInviteVersion]="true"
          *ngFor="let event of invitedEventList" [event]="event"></app-event-card>
      </div>
    </p-tabPanel>
  </p-tabView>
  <app-edit-event-modal *ngIf="isModalOpen1" [isModalOpen1]="isModalOpen1"
    (modalClosed1)="isModalOpen1 = false"></app-edit-event-modal>
</div>