<div class="upload-area draggable-area">
  <div
    class="upload-area"
    [style]="
      filesVet.length > 0
        ? 'opacity: 0.1 !important;'
        : 'opacity: 1 !important;'
    "
    [style]="
      filesVet.length == 1 && maxFileQuantity == 1
        ? 'opacity: 0 !important;'
        : 'opacity: 1 !important;'">
    <svg-icon class="upload-unfilled" name="upload-unfilled"></svg-icon>
    <span>{{ inputMessage }}</span>
    <input
      [disabled]="disableUpload"
      id="inputPhotos"
      class="draggable-area"
      draggable="true"
      (change)="filesUploaded($event)"
      type="file"
      accept="{{ allowedTypes }}"
      multiple
      class="fileupload"
    />
    
  </div>
  <div [class.fullSizePic]="maxFileQuantity == 1" [class.uploaded]="filesVet.length == 1" class="show-pic-overlay">
    <ng-container *ngIf="filesVet.length > 0; else noImages">
      <div class="picture" *ngFor="let picture of filesVet">
        <svg-icon (click)="removePic(picture)" class="trash-bin" name="trash-bin"></svg-icon>
        <img [src]="picture.file ? picture.file : picture" alt="" />
      </div>
      
      
    </ng-container>
    <ng-template #noImages>
      <span>Nenhuma imagem selecionada</span>
    </ng-template>

    <span class="suggested-sizes" *ngIf="show1920">Tamanhos sugeridos: 1920x1080, 960x540 (Proporção 16:9)</span>
  </div>
  
</div>
