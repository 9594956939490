<p-confirmDialog class="confirmDialog" #cd>
  <ng-template pTemplate="header">
    <h3>Produto salvo com sucesso!</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Ok"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>

<modal [isModalOpen]="isModalOpen" (modalClosed)="modalClosed.emit()">
  <div class="product-wrapper">

    <div class="flex flex-column flex-1">
      <h2 class="text-center mb-2">{{ isEditMode ? 'Editar Produto' : 'Adicionar Novo Produto' }}</h2>

      <draggable-input
      (onFilesUploaded)="getProductPics($event)"
      [isOnlyPictures]="true"
      [show1920]="true"
      [currentEventImage]="product.thumbnail"
    ></draggable-input>

    <div class="form-item col-md-12">
        <label for="product-name">Nome</label>
        <input
          class="input"
          id="product-name"
          [disabled]="isReadOnly"
          type="text"
          placeholder="Digite o nome do produto..."
          [(ngModel)]="product.produto_name"
        />
      </div>

      <div class="d-flex row form-row">
        <div class="form-item col-md-7">
            <label for="product-type">Tipo</label>
            <p-dropdown
              [options]="productTypes"
              [disabled]="isReadOnly"
              [(ngModel)]="product.category"
              placeholder="Selecione o tipo"
              class="d-flex"
              [optionValue]="'value'"
              id="product-type"
              [optionLabel]="'title'"
            ></p-dropdown>
          </div>
          <div class="form-item col-md-5">
            <label for="product-price" class="block price"
              >Preço
              <svg-icon
                pTooltip="Taxação de 20% pela plataforma."
                tooltipPosition="top"
                name="question-mark-circled-filled"
              ></svg-icon>
            </label>
            <input
              class="input"
              id="product-price"
              [disabled]="isReadOnly"
              type="number"
              placeholder="00,00"
              pInputText
              [(ngModel)]="product.value"
            />
          </div>
      </div>

      <div class="form-item col-md-12 textarea">
        <label for="product-description">Descrição:</label>
        <textarea
          id="product-description"
          [disabled]="isReadOnly"
          placeholder="Especifique os detalhes do produto..."
          [(ngModel)]="product.description"
        >
        </textarea>
      </div>
      <div class="row delete-row" *ngIf="product.id">
        <button (click)="deleteProduct()"class="col-md-12 primary-button">Remover</button>
      </div>
      <div class="row save-row">
        <button (click)="saveProduct()" class="col-md-12 primary-button">Salvar</button>
      </div>

    </div>
  </div>
</modal>
