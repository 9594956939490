import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http:HttpClient) { }

  addCard(cardInfo: any) {
    return this.http.post(environment.paymentApiUrl + '/cards', cardInfo);
  }

  getCardsByCustomerId(cpf: any) {
    // return this.http.get(environment.paymentApiUrl + '/customers/' + cpf + '/cards');
    return this.http.get(environment.paymentApiUrl + '/customers' + '/cards');
  }
}
