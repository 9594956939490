<div class="landing-page-wrapper">
  <div class="background">
    <img class="bg-img" src="/assets/images/background.jpg" alt="youtube logo">
    <video autoplay muted loop>
      <source src="assets/videos/home.mp4" type="video/mp4">
    </video>
  </div>
  <div class="rocket-form">
    <div class="roldana">
      <img src="/assets/images/glasses.png">
    </div>
  </div>

  <div class="main-title">ESTAMOS CHEGANDO...</div>

  <div class="main-div">
    <div class="title">Quem somos nós</div>
    <div class="content">O Agoora é um aplicativo de realidade virtual, que promove reuniões entre pessoas de diferentes
      locais físicos num mesmo espaço virtual, usando as tecnologias mais recentes de metaverso. Ideal para reuniões,
      sessões de conversa, terapia, e outras atividades em grupo, que agora podem ser realizadas também no metaverso,
      removendo a barreira da distancia entre as pessoas.</div>
    <button class="see-more">
      <img src="/assets/images/youtube-logo-uncircled-unfilled.svg" alt=""> Assista uma demonstração
    </button>
    <div class="icon">
      <img src="/assets/images/vr-guy.svg" alt="">
    </div>
  </div>
  <div class="social-networks">
    <img class="social-network" src="/assets/images/youtube-cirled-unfilled.svg" alt="youtube logo">
    <img class="social-network" src="/assets/images/twitter-circled-unfilled.svg" alt="twitter logo">
    <img class="social-network" src="/assets/images/instagram-circled-unfilled.svg" alt="instagram logo">
    <img class="social-network" src="/assets/images/facebook-circled-unfilled.svg" alt="facebook logo">
  </div>
</div>
