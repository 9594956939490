import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, Roles } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isGuest = this.authService.getRoles().indexOf(Roles.Guest) > -1;
    const isHost = this.authService.getRoles().indexOf(Roles.Host) > -1;
    const isMaster = this.authService.getRoles().indexOf(Roles.Master) > -1;

    if (!this.authService.isLogged()) {
      this.authService.storeIntention(state.url);
      this.router.navigateByUrl('/login');
      return false;
    }else if (this.authService.isLogged() && (state.url == '/' || (!isGuest && state.url == '/app'))) {

      if (isGuest) {
        this.router.navigateByUrl('/app');
      }

      /* if (isHost) {
        this.router.navigateByUrl('/calendar');
        this.dashboardService.refreshDashboardLinks.next();
      } */
    }
    return true;
  }
}
