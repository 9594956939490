import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-guest-picker-modal',
  templateUrl: './guest-picker-modal.component.html',
  styleUrls: ['./guest-picker-modal.component.scss'],
})
export class GuestPickerModalComponent implements OnInit {
  @Output()
  modalClosed: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onGuestFound: EventEmitter<any> = new EventEmitter<any>();
  @Input() isModalOpen: boolean = true;
  @Input() isEditing: boolean = true;
  @Input() user: any;

  @Input() choosenPassword: string = '';
  email: string = '';
  foundUser: any;
  allUsersList: any;

  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  searchGuest() {
    /*https://primeng.org/chips*/
    if (!this.email || this.email.indexOf('@') < 0) {
      return;
    }
    this.userService.checkUserEmail(this.email.toLowerCase().trim()).subscribe(
      (result: any) => {
        console.log('user_id', result);
        const user = {
          id: result.data.user_id,
          email: this.email,
          initials: (
            this.email[0] +
            (this.email.split('@')[0].length > 1 ? this.email[1] : '')
          ).toUpperCase(),
        };

        this.onGuestFound.emit(user);
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: error.error.message ?? error.error,
        });
      }
    );
  }
}
