<app-navbar *ngIf="!hideNavbar"></app-navbar>
<div class="container-fluid main-app">
  <router-outlet></router-outlet>
  <!-- <p-toast></p-toast> -->


  <div
    class="cart-badge"
    pTooltip="Você tem {{ getCartProductQuantity }} produtos no carrinho."
    *ngIf="isShowingCartBadge"
    (click)="goToCart()"
  >
    <svg-icon name="badge-cart"></svg-icon>
    <div class="cart-quantity">
       {{ getCartProductQuantity }}
    </div>
  </div>
</div>
