import { Address } from '../user';


export class PaymentDto {
  amount: number = 0;
  address: Address = new Address();
  phoneNumber: string = '';
  creditCard?: CreditCard = new CreditCard();
  plan_id?: string = '';
  payment_method: paymentType = paymentType.credit_card;
}

export class CreditCard {
  number: string = '';
  holderName: string = '';
  holderCpf: string = '';
  dueMonth: string = '';
  dueYear: string = '';
  confirmationDigits: string = '';
}

export enum paymentType{
  credit_card = 'Cartao de Crédito',
  pix = 'Pix',
  boleto = 'Boleto',
  avulso = 'pix-boleto',
}
