import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, Observable, of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-hosts-list',
  templateUrl: './hosts-list.component.html',
  styleUrls: ['./hosts-list.component.scss']
})
export class HostsListComponent implements OnInit {

  isShowFilterModal: boolean = false;
  hosts: any[] = [];
searchText: string = "";
filteredHosts: any[] = [];
  constructor(
    private http: HttpClient, 
    private userService: UserService, 
    private messageService: MessageService,
    private router: Router) {}

  ngOnInit(): void {
    this.userService.getHosts().subscribe((result: any) => {
        this.hosts = result.data;
        this.filteredHosts = result.data;
        
    }, error => {
        this.messageService.add({
            severity:'error',
            summary:'Erro',
            detail:'Ocorreu um erro ao carregar hosts'
        })
    })
  }

  openHostPage(host: any) {
    this.router.navigateByUrl('/hosts/' + host.id);
  }
  searchUsers(): void {
    const searchText = this.searchText.toLowerCase();
    this.filteredHosts = this.hosts.filter((user: any) =>
    user.name.toLowerCase().includes(searchText) ||
    user.nickname.toLowerCase().includes(searchText) ||
    user.description.toLowerCase().includes(searchText)
    );  
  }

}
