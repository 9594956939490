<div class="user-home-wrapper">
  <section id="title">Próximos eventos</section>
  <section id="events">
    <div class="grid-container">
      <div *ngFor="let event of events" class="event">
        <div class="banner"><img [src]="event.url_thumbnail" alt="Event">
          <div class="title">{{event.title}}</div>
        </div>
        <!-- <div class="banner">{{event.banner}}</div> -->
        <div class="date description">
          <img src="assets/images/calendar.svg" alt="">
          {{event.created_at | date: "dd/MM/yyyy"}}, {{event.created_at | date: "HH:mm"}}
        </div>
        <div class="time description">
          <img src="assets/images/clock.svg" alt="">
          {{event.timeTillDate | date: "dd/MM/yyyy"}}
        </div>
        <button type="button" class="btn btn-info">Inscrever-se</button>
      </div>
    </div>
  </section>
</div>
