import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { UserFormComponent } from './user-form/user-form.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { EditProductModalComponent } from './edit-product-modal/edit-product-modal.component';
import { GalleriaModule } from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { UserProfileModalComponent } from './user-profile-modal/user-profile-modal.component';
import { ModalComponent } from './modal/modal.component';
import { EditEventModalComponent } from './edit-event-modal/edit-event-modal.component';
import { MediaAdminFormComponent } from './media-admin-form/media-admin-form.component';
import { DraggableInputComponent } from './draggable-input/draggable-input.component';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { EventsComponent } from './events/events.component';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { EventCardComponent } from './events/event-card/event-card.component';
import { EventDetailsComponent } from './events/event-details/event-details.component';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { HostsListComponent } from '../guest/hosts-list/hosts-list.component';
import { PassValidationComponent } from './pass-validation/pass-validation.component';
import { GuestPickerModalComponent } from './guest-picker-modal/guest-picker-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
  declarations: [
    SvgIconComponent,
    UserFormComponent,
    EditProductModalComponent,
    UserProfileModalComponent,
    ModalComponent,
    EditEventModalComponent,
    MediaAdminFormComponent,
    DraggableInputComponent,
    EventsComponent,
    NavbarComponent,
    EventCardComponent,
    EventDetailsComponent,
    HostsListComponent,
    PassValidationComponent,
    GuestPickerModalComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    GalleriaModule,
    ButtonModule,
    TooltipModule,
    SliderModule,
    TabViewModule,
    ToastModule,
    MultiSelectModule,
    AvatarModule,
    AvatarGroupModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
  ],
  exports: [
    SvgIconComponent,
    UserFormComponent,
    EditProductModalComponent,
    EditEventModalComponent,
    ModalComponent,
    MediaAdminFormComponent,
    EventsComponent,
    UserProfileModalComponent,
    NavbarComponent,
    EventDetailsComponent,
    HostsListComponent,
    EventCardComponent,
    PassValidationComponent,
  ],
})
export class SharedModule {}
