<div class="container col-md-8 justify-content-center">
  <div class="logo row"> <img class="col-md-3" src="assets/layout/images/logo-azul-scuro.png" alt=""> </div>
  <div class="content-wrap col-md-8">
    <h1 class="title row">{{userName}}, estamos quase lá...</h1>
    <h2 class="subtitle">Falta confirmar seu endereço de e-mail</h2>
    <div class="text content">Estamos felizes em ter você com a gente. Pra que você tenha uma ótima experiência,
      queremos ter
      certeza de você é
      você. Pra isso, clica aqui embaixo pra confirmar seu endereço de e-mail:</div>
    <button>Confirmar e-mail</button>
    <div class="text advise">Caso você não seja {{userName}}, favor, desconsidere esta mensagem.</div>
  </div>
  <div class="footer">A Agoora VR nunca lhe enviará um e-mails solicitando senha, cartão de crédito ou número de conta
    bancária.</div>
  <div class="social-networks">
    <img class="social-network" src="/assets/images/youtube-cirled-unfilled.svg" alt="youtube logo">
    <img class="social-network" src="/assets/images/twitter-circled-unfilled.svg" alt="twitter logo">
    <img class="social-network" src="/assets/images/instagram-circled-unfilled.svg" alt="instagram logo">
    <img class="social-network" src="/assets/images/facebook-circled-unfilled.svg" alt="facebook logo">
  </div>
</div>
